import React from 'react';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';

import { IS_PRODUCTION } from 'utils';

const matomoBaseUrl = 'https://stats.luca-app.de/';
const matomoSiteId = 2;

export const MATOMO_BASE_URL = process.env.MATOMO_BASE_URL || matomoBaseUrl;
export const MATOMO_SITE_ID = process.env.MATOMO_SITE_ID
  ? Number.parseInt(process.env.MATOMO_SITE_ID, 10)
  : matomoSiteId;

const trackerInstance = createInstance({
  urlBase: MATOMO_BASE_URL,
  siteId: MATOMO_SITE_ID,
  disabled: !IS_PRODUCTION,
  heartBeat: {
    active: true,
    seconds: 10,
  },
  linkTracking: true,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

type TrackerProviderProperties = {
  children: React.ReactNode;
};

export function TrackerProvider({ children }: TrackerProviderProperties) {
  return <MatomoProvider value={trackerInstance}>{children}</MatomoProvider>;
}
