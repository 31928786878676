export const API_PATH = process.env.REACT_APP_API_PATH || '/api';

export const BASE_PATH = '/webapp';
export const CLUB_PATH = '/club';
export const PREORDER_PATH = '/preorder';
export const STAY_PATH = '/stay';
export const LUCA_PAY_PATH = '/pay';
export const HOTEL_PATH = '/hotel';
export const SELF_CHECKOUT_PATH = '/selfcheckout';
export const EXPERIENCES_PATH = '/experiences';
export const RECEIPTS_PATH = '/receipts';

export const EXPERIENCE_ROUTE = `${EXPERIENCES_PATH}/:experienceId`;

export const SELF_CHECKOUT_ROUTE = `${SELF_CHECKOUT_PATH}/:discoverId`;
export const SELF_CHECKOUT_PAY_ROUTE = `${SELF_CHECKOUT_PATH}/:discoverId/order/:orderId`;
export const SELF_CHECKOUT_SUCCESS_ROUTE = `${SELF_CHECKOUT_PATH}/:discoverId/checkout/:checkoutId/success`;
export const SELF_CHECKOUT_KIOSK_ROUTE = `${SELF_CHECKOUT_PATH}/:discoverId/kiosk/:locationId`;
export const SELF_CHECKOUT_SHOP_ROUTE = `${SELF_CHECKOUT_PATH}/:discoverId/shop/:locationId`;

// Stay routes
export const LUCA_STAY_ROUTE = `${STAY_PATH}/:discoverId`;

export const AMOUNT_ROUTE = `${LUCA_PAY_PATH}/amount`;
export const ORDER_AMOUNT_ROUTE = `${LUCA_PAY_PATH}/order/amount`;
export const PREORDER_AMOUNT_ROUTE = `${LUCA_PAY_PATH}/preorder/amount`;

export const PAYMENT_ERROR_ROUTE = `${LUCA_PAY_PATH}/error_payment`;
export const CHECKOUT_CANCEL_ROUTE = `${LUCA_PAY_PATH}/cancel_checkout`;
export const COMPLETE_CHECKOUT_ROUTE = `${LUCA_PAY_PATH}/complete_checkout`;
export const REGISTER_ACCOUNT_ROUTE = `${LUCA_PAY_PATH}/register_account`;
export const BILL_ROUTE = `${LUCA_PAY_PATH}/bill`;

export const LUCA_SHOPPER_RECEIPTS_ROUTE = `${RECEIPTS_PATH}/:discoverId/shopperReceipts`;
export const DISCOVERY_ROUTE = `/discovery`;

export const PREORDER_CHECKOUT_SUCCESS_ROUTE = `${LUCA_PAY_PATH}/complete_preorder_checkout`;
export const PREORDER_CHECKOUT_ERROR_ROUTE = `${LUCA_PAY_PATH}/error_preorder_payment`;

export const LICENSES_ROUTE = `${BASE_PATH}/licenses`;

export const LUCA_PREORDER_ORDER_ROUTE = `${PREORDER_PATH}/:discoverId`;

export const LANDING_PAGE_ROUTE = `${BASE_PATH}/:scannerId`;
export const HOTEL_LANDINGPAGE_ROUTE = `${HOTEL_PATH}/:locationId`;
export const MENU_PAGE_ROUTE = `${BASE_PATH}/:locationId/menu`;
export const LUCA_CLUB_PAGE_ROUTE = `${CLUB_PATH}/vip-club-invitation/:inviteIdOrCode`;
export const CLUB_MEMBER_REFERRAL_PAGE_ROUTE = `${CLUB_PATH}/club-member-referral`;
export const CLUB_MEMBER_APPLICATION_PAGE_ROUTE = `${CLUB_PATH}/club-member-application/:referralId`;

export const EMAIL_SUCCESS = `${BASE_PATH}/userEmailConfirmSuccess`;
export const LOGIN_SUCCESS = `${BASE_PATH}/userLoginConfirmSuccess`;

export const EMAIL_EXISTS_ERROR = `${BASE_PATH}/userEmailAlreadyConfirmed`;
export const EMAIL_ERROR = `${BASE_PATH}/userEmailConfirmError`;
export const LOGIN_ERROR = `${BASE_PATH}/userLoginConfirmError`;

export const VOUCHER_REDEEM_SUCCESS = `${BASE_PATH}/voucherRedeemConfirmSuccess`;
export const VOUCHER_REDEEM_EXISTS_ERROR = `${BASE_PATH}/voucherRedeemAlreadyConfirmed`;
export const VOUCHER_REDEEM_ERROR = `${BASE_PATH}/voucherRedeemConfirmError`;

export const RESERVATIONS_PAGE = `${BASE_PATH}/reservations`;
export const RESERVATIONS_CANCEL_ROUTE = `${LUCA_PAY_PATH}/reservations/cancel`;

export const INSTALL_REDIRECT = `${BASE_PATH}/install`;
export const REDIRECT_TEST = `${BASE_PATH}/redirecttest`;

export const PRODUCT_MADE_REDIRECT = `${BASE_PATH}/pm/:productMadeId`;

export const APPLE_SSO_SUCCESS = `${BASE_PATH}/userSignInWithAppleSuccess`;

export const APPLE_SSO_ERROR = `${BASE_PATH}/userSignInWithAppleError`;

export const USER_EXISTS_ERROR = `${BASE_PATH}/userAlreadyExist`;
