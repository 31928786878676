import { Dayjs } from 'dayjs';

import { DiscoverGroupContainerTypes } from 'types';

import { InvoiceAddressStayType, ResidentialAddressStayType } from './consumer';

export enum IdentificationTypeEnum {
  SOCIAL_INSURANCE_NUMBER = 'SocialInsuranceNumber',
  PASSPORT_NUMBER = 'PassportNumber',
  ID_NUMBER = 'IdNumber',
  DRIVER_LICENSE_NUMBER = 'DriverLicenseNumber',
}

export type IdentityDocumentType = {
  issueDate: string;
  expireDate: string;
  uploadImageId: string | null;
  documentNumber: string;
  identificationIssuingAuthority: string;
  type?: IdentificationTypeEnum;
  birthPlace?: string;
};

export type StayAdditionalGuestType = {
  id?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  birthDate?: string | null;
};

export type PersonalDataStayType = {
  id?: string;
  title: string;
  gender: string;
  membershipNumber?: string;
  firstName: string;
  middleInitial?: string;
  lastName: string;
  birthDate?: string | null;
  nationality: string;
  identityDocument?: IdentityDocumentType | null;
  phone: string;
  email: string;
};

export type StayUserDataType = {
  personalData: PersonalDataStayType;
  residentialData?: ResidentialAddressStayType[];
  invoiceData: InvoiceAddressStayType[];
  guestData: StayAdditionalGuestType[];
};

type BookingMeldescheinResponseType = {
  uuid: string;
  createdAt: Dayjs;
  reservationId: string;
  guestCount: number;
};

export type ResidentialAddressType = {
  addressLine1: string;
  postalCode: string;
  city: string;
  countryCode: string;
};

export type InvoiceAddressType = {
  companyName: string;
  email: string;
  vat: string;
  addressLine1: string;
  postalCode: string;
  city: string;
  countryCode: string;
};

export type StayPrimaryGuestType = {
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  birthday: Dayjs;
  nationalityCountryCode: string;
  identificationNumber: string;
  identificationIssueDate: string;
  identificationExpiryDate: string;
  identificationIssuingAuthority: string;
  identificationType: string;
  preferredLanguage: string;
  birthPlace: string;
  membership?: {
    membershipType: string | null;
    membershipIdNo: string | null;
  };
  residentialAddress: ResidentialAddressType;
  invoiceAddress?: InvoiceAddressType;
};

type StayUnitGroupType = {
  id: string;
  code?: string;
  name?: string;
  description?: string;
  type: string;
};

export enum ActionType {
  'CHECK_IN' = 'CheckIn',
  'CHECK_OUT' = 'CheckOut',
  'CANCEL' = 'Cancel',
  'AMEND_TIME_SLICES' = 'AmendTimeSlices',
  'AMEND_ARRIVAL' = 'AmendArrival',
  'AMEND_DEPARTURE' = 'AmendDeparture',
  'NO_SHOW' = 'NoShow',
  'ASSIGN_UNIT' = 'AssignUnit',
  'UNASSIGN_UNIT' = 'UnassignUnit',
  'REMOVE_CITY_TAX' = 'RemoveCityTax',
  'ADD_CITY_TAX' = 'AddCityTax',
  'REMOVE_SERVICE' = 'RemoveService',
}

export type ActionItemReasonModel = {
  code:
    | 'CheckInNotAllowedForReservationNotInStatusConfirmed'
    | 'CheckInNotAllowedBeforeArrivalDate'
    | 'CheckInNotAllowedAfterDepartureDateTime'
    | 'CheckInNotAllowedWithoutUnitAssignedForWholeStay'
    | 'CheckOutNotAllowedForReservationNotInStatusInHouse'
    | 'CheckOutNotAllowedForPastReservationNotInStatusConfirmedOrInHouse'
    | 'CheckOutNotAllowedWithDepartureDateMoreThanOneDayInTheFuture'
    | 'CancelNotAllowedForReservationNotInStatusConfirmed'
    | 'AmendNotAllowedForNotAmendableTimeSlices'
    | 'AmendArrivalNotAllowedForNotAmendableTimeSlices'
    | 'AmendArrivalNotAllowedForReservationNotInStatusConfirmed'
    | 'AmendDepartureNotAllowedForReservationNotInStatusConfirmedOrInHouse'
    | 'AmendDepartureNotAllowedForReservationDepartureDateTooFarInThePast'
    | 'NoShowNotAllowedForReservationNotInStatusConfirmed'
    | 'NoShowNotAllowedBeforeArrivalDate'
    | 'AssignUnitNotAllowedForReservationInThePast'
    | 'AssignUnitNotAllowedForReservationNotInStatusConfirmedOrInHouse'
    | 'UnassignUnitNotAllowedForReservationInThePast'
    | 'UnassignUnitNotAllowedForReservationNotInStatusConfirmed'
    | 'UnassignUnitNotAllowedForReservationWithoutUnit'
    | 'RemoveCityTaxNotAllowedForReservationNotInStatusConfirmedOrInHouse'
    | 'RemoveCityTaxNotAllowedForReservationWithPostedCharges'
    | 'RemoveCityTaxNotAllowedForReservationWithoutCityTax'
    | 'AddCityTaxNotAllowedForReservationNotInStatusConfirmedOrInHouse'
    | 'AddCityTaxNotAllowedForReservationWithCityTax'
    | 'AddCityTaxNotAllowedForReservationForRatePlanNotSubjectToCityTax'
    | 'AddCityTaxNotAllowedForReservationWithPostedCharges'
    | 'RemoveServiceNotAllowedForReservationNotInStatusConfirmedOrInHouse'
    | 'RemoveServiceNotAllowedForReservationInThePast';
  message: string;
};

export type ActionItem = {
  action: ActionType;
  isAllowed: boolean;
  reasons: ActionItemReasonModel[];
};

export type ChargeType = {
  name: string;
  price: number;
  quantity: number;
  serviceDate: string;
};
export type FolioPaymentType = {
  id: string;
  method: string;
  paymentDate: string;
  receipt: string | null;
  amount: number;
};

export type FolioItemType = {
  total: number;
  charges: ChargeType[];
  payments: FolioPaymentType[];
  id: string;
  isBusinessFolio: boolean;
  paymentCanBeCaptured: boolean;
};

export enum HotelReservationStatusEnum {
  CONFIRMED = 'Confirmed',
  PRE_CHECKEDIN = 'PreCheckedin',
  IN_HOUSE = 'InHouse',
  CHECKED_OUT = 'CheckedOut',
  CANCELED = 'Canceled',
  NO_SHOW = 'NoShow',
}

export type StayBookingType = {
  status: HotelReservationStatusEnum;
  guestCount: number;
  arrivalDate: Dayjs;
  departureDate: Dayjs;
  primaryGuest: StayPrimaryGuestType;
  code: string;
  id: string;
  confirmationNumber: string | null;
  consumer: null;
  meldeschein: BookingMeldescheinResponseType | null;
  unitGroup: StayUnitGroupType;
  unitId: string;
  externalReference: string | null;
  unitName: string;
  actions: ActionItem[] | [];
};

export enum LocationGroupFolioPaymentType {
  PRE_PAYMENT = 'PRE_PAYMENT', // Pre-payment of folio
  PRE_AUTHORIZATION = 'PRE_AUTHORIZATION', // Pre-authorization of the entire folio
  ZERO_VALUE_SCA = 'ZERO_VALUE_SCA', // 0€ SCA authentication/charge
}

export type GetReservationFoliosResponseType = {
  folios: FolioItemType[];
  totalAmountPaid: number;
  totalOpenAmount: number;
  currency: string;
  pmsReference: string;
  folioPaymentType: LocationGroupFolioPaymentType;
  nonCapturePayment: {
    paymentVerifier: string;
    tipAmount: number;
    uuid: string;
    payAmount: number;
    captured: boolean;
  } | null;
};

export type LucaStayCheckoutStateType = DiscoverGroupContainerTypes & {
  folioResponse: GetReservationFoliosResponseType;
  primaryGuestLastName: string | null;
  checkoutId?: string;
};

//
// POST pay/api/v3/consumers/meldescheins
//
type CreateMeldescheinAddressRequestType = {
  addressLine1: string;
  addressLine2?: string;
  postalCode: string;
  city: string;
  state?: string;
  countryCode: string;
};
type CreateMeldescheinInvoiceAddressRequestType =
  CreateMeldescheinAddressRequestType & {
    companyName?: string;
    vat?: string;
  };
type CreateMeldescheinStayGuestType = {
  title?: string;
  gender?: string;
  firstName?: string;
  lastName: string;
  birthday?: string;
};
type CreateMeldescheinStayGuestPrimaryType = CreateMeldescheinStayGuestType & {
  nationalityCountryCode?: string;
  identificationNumber?: string;
  identificationIssuingCountry?: string;
  identificationIssuingAuthority?: string;
  identificationIssueDate?: string;
  identificationExpiryDate?: string;
  identificationType?: IdentificationTypeEnum;
  passportImageKeys?: string[];
  visaImageKeys?: string[];
  preferredLanguage?: string;
  birthPlace?: string;
  phone: string;
  email: string;
  consumerId?: string;
  residentialAddress?: CreateMeldescheinAddressRequestType;
  invoiceAddress?: CreateMeldescheinInvoiceAddressRequestType;
  membership?: {
    membershipType: string | null;
    membershipIdNo: string | null;
  };
};
export type CreateMeldescheinRequestType = {
  sendMembershipRequest?: boolean;
  discoverId: string;
  pmsReference?: string | null;
  primaryGuest: CreateMeldescheinStayGuestPrimaryType;
  additionalGuests?: CreateMeldescheinStayGuestType[];
  signature: string;
  isPrivateStay: boolean;
  arrivalDate: string;
  departureDate: string;
  guestCount: number;
  storeInConsumer?: boolean;
};

export type CreateMeldescheinResponseType = {
  uuid: string;
  locationId: string;
  reservationId: string | null;
  stayReservationId: string;
  pmsReference: string;
  primaryGuest: StayPrimaryGuestType;
  additionalGuests: StayAdditionalGuestType[];
  signature: string;
  isPrivateStay: boolean;
  arrivalDate: string;
  departureDate: string;
  guestCount: number;
  avsResult: string | null;
  pmsError: string | null;
};

export type ConsumerStayReservationType = {
  uuid: string;
  pmsReference: string;
  externalReference: string | null;
  arrivalDate: Dayjs;
  departureDate: Dayjs;
  guestCount: number;
  unitName: string;
  status: HotelReservationStatusEnum;
  email: string;
  lastName: string;
  fullName: string;
  createdAt: Dayjs;
};

type PageInfoType = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
};

export type GetActiveConsumerReservationsResponseType = {
  totalCount: number;
  pageInfo: PageInfoType;
  data: ConsumerStayReservationType[];
};

export type NextPossibleActionType = {
  actionUrl: string | null;
  actionText: string | null;
  actionDescription: string;
  showBadge: boolean;
};

export type LinkedReservation = {
  pmsReference: string;
};

export type PossibleRoomUpgrade = {
  currentRoom: {
    type: string;
    description: string;
    amount: number;
    currency: string;
  };
  availableOffers: {
    code: string;
    type: string;
    upsellAmount: number;
    currency: string;
    newRoomDescription?: string;
  }[];
};
