export const DESKTOP_CARD_WIDTH = '800px';

export const ITEM_PIDS_WITH_GEDECK = [
  '220416',
  '220417',
  '220418',
  '220419',
  '220420',
  '220421',
  '220422',
  '220423',
  '220424',
  '220425',
  '220426',
  '220427',
  '220556',
  '220557',
  '220558',
  '220559',
  '220560',
];

export const GEDECK_ITEM = {
  pid: '220415',
};

export const DISABLED_OCCURRENCE_LIST: string[] = [
  // Currently emtpy but might be filled later again
];

export const PREMIERE_SHOW_OCCURRENCE_IDS: string[] = [
  // Currently emtpy but might be filled later again
];

// Defaults in minutes
export const DEFAULT_MINIMUM_TIME_BEFORE_SHOW = 720;
export const DEFAULT_MINIMUM_TIME_BEFORE_SLOT = 60;

export const RHEINGOLD_PROMOTION_FLYER_URL =
  'https://app.luca-app.de/api/v4/images/download/3605a19d-c3e4-4806-9a00-20893f9b3a99';
export const LUDWIG_ZWEI_PROMOTION_FLYER_URL =
  'https://app.luca-app.de/api/v4/images/download/c9317720-0342-471b-980e-60d9ab462f10';
