import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import CacheBuster from 'react-cache-buster';

import { TrackerProvider } from 'services/providers';

import 'antd/dist/reset.css';
import './index.css';
import './font.css';
import './ant.css';
import './adyen.css';
import '@adyen/adyen-web/dist/adyen.css';

import { getLanguage } from 'utils';
import { LanguageContainer } from 'containers';
import { messages } from 'messages';
import { App } from 'App.react';
import { ApplicationErrorPage } from 'components/ApplicationErrorPage';
import { LoadingSpinner } from 'components/LoadingSpinner';

import packageInfo from '../package.json';

const queryClient = new QueryClient();
const { version } = packageInfo;

const AppWithLanguage = () => {
  const { currentLocale } = LanguageContainer.useContainer();

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      wrapRichTextChunksInFragment
    >
      <TrackerProvider>
        <HelmetProvider>
          <Helmet>
            <meta name="apple-itunes-app" content="app-id=1531742708" />
          </Helmet>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ApplicationErrorPage>
                <App />
              </ApplicationErrorPage>
            </QueryClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </TrackerProvider>
    </IntlProvider>
  );
};

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isVerboseMode={false}
    loadingComponent={<LoadingSpinner />}
  >
    <LanguageContainer.Provider initialState={getLanguage()}>
      <AppWithLanguage />
    </LanguageContainer.Provider>
  </CacheBuster>,
  document.querySelector('#root')
);
