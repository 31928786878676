import styled, { css } from 'styled-components';

import { getDeviceHeight } from 'utils';

export const AppWrapper = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 610px;
  margin: 0 auto;
  min-height: ${getDeviceHeight()}px;
  height: ${getDeviceHeight()}px;
  position: relative;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const PayAppWrapper = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  max-width: 610px;
  margin: 0 auto;
  min-height: ${window.innerHeight}px;
  padding: 0 16px;
  position: relative;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const DesktopWrapper = styled(AppWrapper)`
  background-color: #1a1a1a;
  max-width: 560px;
`;

export const AppContentContainer = styled.div`
  flex-grow: 1;
`;
