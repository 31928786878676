/* eslint-disable unicorn/prevent-abbreviations */
import en from './en.json';
import de from './de.json';
import el from './el.json';

import reservationsWidgetDe from './reservationsWidgetDe.json';
import reservationsWidgetEn from './reservationsWidgetEn.json';

import analyticsEn from './analyticsEn.json';

export const messages = {
  en,
  de,
  el,
  reservationsWidgetDe,
  reservationsWidgetEn,
  analyticsEn,
};
