import { useNotifications } from 'hooks';
import { useNetworkConnection } from './useNetworkConnection';
import { NetworkStatuses, NetworkStatusInformation } from './networkStatuses';

export const useNetworkConnectionAlert = () => {
  const { infoMessage } = useNotifications();

  const notificationAlert = (networkStatus: NetworkStatusInformation) => {
    const status = networkStatus.isOnline
      ? NetworkStatuses.Online
      : NetworkStatuses.Offline;

    infoMessage(`${status}.details`, `${status}.headline`);
  };

  useNetworkConnection(notificationAlert);
};
