import styled from 'styled-components';
import { Colors } from 'constants/globalStyles';
import { BoldText15Black, NormalText15Black } from 'components/general';
import { Divider } from 'antd';
import { Media } from 'utils';

export const Wrapper = styled.div`
  flex: 1;
  width: 100vw;
  display: flex;
  height: 100%;
  padding: 16px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  box-sizing: border-box;
  flex-direction: column;
  background-color: ${Colors.grey_100};
`;

export const HeaderWrapper = styled.header`
  display: flex;
  padding-left: 32px;
  padding-bottom: 16px;
  align-items: flex-end;
`;

export const HeaderLogo = styled.img`
  height: 48px;
  color: ${Colors.black_100};
`;

export const ErrorHeadline = styled.div`
  color: ${Colors.black_100};
  font-size: 34px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  margin-bottom: 12px;
`;

export const ErrorDescription = styled(NormalText15Black)`
  max-width: 600px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 30px;
`;

export const ErrorGraphic = styled.img`
  max-width: 200px;
  padding: 16px 0 8px;
  object-position: center;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
`;

export const StyledShowMoreLessButton = styled(BoldText15Black)`
  cursor: pointer;
  text-align: center;
  margin: 8px 0;
  text-transform: uppercase;
`;

export const StyledDivider = styled(Divider)`
  border-block-start: 0 black !important;
  max-width: 220px;
  min-width: 450px;

  ${Media.mobile`
    max-width: unset;
    min-width: unset;
  `}
`;
