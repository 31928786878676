import styled from 'styled-components';
import { Media, getDeviceHeight } from 'utils';
import { PrimaryButton, SecondaryDarkButton } from 'components/general';

enum ButtonState {
  WITHOUT_BUTTON = '0px',
  HAS_TWO_BUTTONS = '152px',
  DEFAULT = '88px',
}

export const ContentWrapper = styled.div`
  padding: 32px 16px;
`;

export const Gradient = styled.img`
  height: 260px;
  width: 100%;
  object-fit: cover;
`;

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 16px;
  right: 16px;
  padding-top: 16px;
`;

export const SectionWrapper = styled.div<{
  withoutButton?: boolean;
  isMobile?: boolean;
}>`
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${({ isMobile, withoutButton }) =>
    isMobile
      ? `
      min-height: calc(${getDeviceHeight()}px - ${
          withoutButton ? '32px' : '88px'
        });
      max-height: calc(${getDeviceHeight()}px - ${
          withoutButton ? '32px' : '88px'
        });
    `
      : ''}
`;

export const Logo = styled.img`
  height: 80px;
  width: auto;
  max-width: 180px;
  object-fit: contain;
`;

export const StyledSecondaryButton = styled(SecondaryDarkButton)<{
  $primaryColor: string;
  $buttonTextColor: string;
}>`
  color: ${({ $buttonTextColor }) => $buttonTextColor} !important;
  border-color: ${({ $primaryColor }) => $primaryColor} !important;
  background: transparent !important;

  &:focus,
  &:active,
  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor} !important;
    border-color: ${({ $primaryColor }) => $primaryColor} !important;
    background: transparent !important;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{
  $primaryColor: string;
  $buttonTextColor: string;
}>`
  color: ${({ $buttonTextColor }) => $buttonTextColor} !important;
  border-color: ${({ $primaryColor }) => $primaryColor} !important;
  background-color: ${({ $primaryColor }) => $primaryColor} !important;

  &:focus,
  &:active,
  &:hover {
    color: ${({ $buttonTextColor }) => $buttonTextColor} !important;
    border-color: ${({ $primaryColor }) => $primaryColor} !important;
    background-color: ${({ $primaryColor }) => $primaryColor} !important;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  height: calc(100% - 32px);
`;

export const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 16px;
  margin-top: 24px;
  width: 100%;
  background: rgb(5, 5, 25);
  border-radius: 4px;
`;

export const ButtonsSpacer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  background: white;
  width: 100%;
  min-height: ${getDeviceHeight()}px;
  max-height: ${getDeviceHeight()}px;
  position: relative;
  ${Media.mobile`
    max-width: 610px;
  `}
`;

export const PoweredByPositioner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 16px;
  > p {
    margin: 4px 0;
  }
`;

export const ResponsiveSectionWrapper = styled.div<{
  withoutButton?: boolean;
  isDesktop?: boolean;
  hasTwoButtons?: boolean;
}>`
  overflow-y: scroll;
  padding: 0 16px;
  color: black;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${({ isDesktop, withoutButton, hasTwoButtons }) => {
    const subtractionValue = withoutButton
      ? ButtonState.WITHOUT_BUTTON
      : hasTwoButtons
      ? ButtonState.HAS_TWO_BUTTONS
      : ButtonState.DEFAULT;

    return `height: calc(${
      isDesktop ? '100%' : `${getDeviceHeight()}px`
    } - ${subtractionValue});
    `;
  }}
`;
