import {
  LocationTableResponseType,
  TableData,
  hashedUrlAdditionalInformationType,
} from 'types';
import { LUCA_PAY_PATH } from 'constants/routes';

export const getPaymentRoute = ({
  locationId,
  table,
  tableInfo,
  additionalInfo,
}: {
  locationId?: string;
  table?: TableData;
  tableInfo: TableData | null;
  additionalInfo?: hashedUrlAdditionalInformationType;
}) => {
  const amount = additionalInfo?.pay?.a;
  const label = additionalInfo?.pay?.l;

  return `${LUCA_PAY_PATH}/${locationId}?${new URLSearchParams({
    ...(table ? { table: tableInfo?.tableId } : {}),
    ...(amount ? { amount } : {}),
    ...(label ? { label } : {}),
  }).toString()}`;
};

export const getMenuRoute = ({
  locationId,
  tableInfo,
  table,
  tableId,
  backFromPaymentScreen = false,
  isHotelOrdering = false,
  hash = '',
}: {
  locationId?: string;
  tableInfo?: TableData | null;
  table?: LocationTableResponseType | null;
  tableId?: string | null;
  isHotelOrdering?: boolean;
  backFromPaymentScreen?: boolean;
  hash?: string;
}) => {
  const searchParameters = {
    ...(isHotelOrdering ? { isHotelOrdering: 'true' } : {}),
    ...(backFromPaymentScreen ? { backFromPaymentScreen: 'true' } : {}),
    ...(tableInfo ? { table: tableInfo?.tableId } : {}),
    ...(table ? { table: table?.id } : {}),
    ...(tableId ? { table: tableId } : {}),
  };
  return `/webapp/${locationId}/menu?${new URLSearchParams(
    searchParameters
  ).toString()}${hash}`;
};
