/* eslint-disable max-lines */
import { IntlShape } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { schengenRaumCountries, GERMAN_COUNTRY_CODE } from 'constants/stay';
import {
  STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY,
  STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY,
} from 'constants/localStorage';
import {
  germanDateStringFormat,
  stayIncomingStringFormat,
} from 'constants/time';
import {
  FolioEnrichedStayBookingType,
  FolioItemType,
  HotelReservationStatusEnum,
  IdentificationTypeEnum,
  InvoiceAddressStayType,
  PersonalDataStayType,
  ResidentialAddressStayType,
  StayAdditionalGuestType,
  StayBookingType,
  StayPrimaryGuestType,
  StayUserDataType,
} from 'types';
import { STAY_PATH } from 'constants/routes';
import { getLanguage } from 'utils';

import {
  GENERIC_REQUIRED_ERROR,
  INVALID_FORMAT_ERROR,
  MAX_LENGTH_ERROR,
  MIN_LENGTH_ERROR,
} from './yup';

export const areAllMandatoryInvoiceDataFieldsFilled = (
  data: Omit<InvoiceAddressStayType, 'vat'>
): boolean => {
  if (!data) {
    return false;
  }

  return Object.values(data).every(value => value != null && value !== '');
};

export const areAllMandatoryPersonalDataFieldsFilled = (
  data?: PersonalDataStayType
): boolean => {
  if (!data) {
    return false;
  }

  const mandatoryFields: (keyof PersonalDataStayType)[] = [
    'title',
    'gender',
    'firstName',
    'lastName',
    'nationality',
    'phone',
    'email',
  ];

  return mandatoryFields.every(
    field =>
      Object.prototype.hasOwnProperty.call(data, field) && data[field] !== ''
  );
};

export const areAllMandatoryAddressFieldsFilled = (
  data?: ResidentialAddressStayType
): boolean => {
  if (!data) {
    return false;
  }

  const mandatoryFields: (keyof ResidentialAddressStayType)[] = [
    'addressLine1',
    'postalCode',
    'city',
    'countryCode',
  ];

  return mandatoryFields.every(
    field =>
      Object.prototype.hasOwnProperty.call(data, field) && data[field] !== ''
  );
};

export const isFromSchengenRaum = (nationality?: string) =>
  !!nationality && schengenRaumCountries.includes(nationality);

export const getIdentityDocument = (
  primaryGuest: StayPrimaryGuestType,
  storedPrimaryGuest?: PersonalDataStayType | null
) => ({
  ...(primaryGuest.identificationIssueDate ||
  storedPrimaryGuest?.identityDocument?.issueDate
    ? {
        issueDate: primaryGuest.identificationIssueDate
          ? dayjs(
              primaryGuest.identificationIssueDate,
              stayIncomingStringFormat,
              true
            ).format(germanDateStringFormat)
          : storedPrimaryGuest?.identityDocument?.issueDate,
      }
    : {}),
  ...(primaryGuest.identificationExpiryDate ||
  storedPrimaryGuest?.identityDocument?.expireDate
    ? {
        expireDate: primaryGuest.identificationExpiryDate
          ? dayjs(
              primaryGuest.identificationExpiryDate,
              stayIncomingStringFormat,
              true
            ).format(germanDateStringFormat)
          : storedPrimaryGuest?.identityDocument?.expireDate,
      }
    : {}),
  ...(primaryGuest.identificationType ||
  storedPrimaryGuest?.identityDocument?.type
    ? {
        type:
          primaryGuest.identificationType ||
          storedPrimaryGuest?.identityDocument?.type,
      }
    : {}),
  ...(primaryGuest.identificationNumber ||
  storedPrimaryGuest?.identityDocument?.documentNumber
    ? {
        documentNumber:
          primaryGuest.identificationNumber ||
          storedPrimaryGuest?.identityDocument?.documentNumber,
      }
    : {}),
  ...(primaryGuest.birthPlace ||
  storedPrimaryGuest?.identityDocument?.birthPlace
    ? {
        birthPlace:
          primaryGuest.birthPlace ||
          storedPrimaryGuest?.identityDocument?.birthPlace,
      }
    : {}),
  ...(primaryGuest.identificationIssuingAuthority ||
  storedPrimaryGuest?.identityDocument?.identificationIssuingAuthority
    ? {
        identificationIssuingAuthority:
          primaryGuest.identificationIssuingAuthority ||
          storedPrimaryGuest?.identityDocument?.identificationIssuingAuthority,
      }
    : {}),
});

export const getMonthMap = (intl: IntlShape) => ({
  '1': 'Jan',
  '2': 'Feb',
  '3': intl.formatMessage({ id: 'month.mar' }),
  '4': 'Apr',
  '5': intl.formatMessage({ id: 'month.may' }),
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': intl.formatMessage({ id: 'month.oct' }),
  '11': 'Nov',
  '12': intl.formatMessage({ id: 'month.dec' }),
});

export const getDateConfig = (monthMap: any) => ({
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
  month: {
    // @ts-ignore no types for the corresponding package (react-mobile-datepicker)
    format: (value: string) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
});

export const getPersonalDataGenderOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'generic.man' }),
    value: 'male',
    default: true,
  },
  {
    label: intl.formatMessage({ id: 'generic.woman' }),
    value: 'female',
  },
  {
    label: intl.formatMessage({ id: 'generic.nonBinary' }),
    value: 'non-binary',
  },
  {
    label: intl.formatMessage({ id: 'generic.noSay' }),
    value: 'no-say',
  },
];

export const getPersonalDataSalutationOptions = (intl: IntlShape) => {
  const baseSalutations = [
    {
      label: intl.formatMessage({ id: 'generic.mr' }),
      value: 'Mr',
      default: true,
    },
    {
      label: intl.formatMessage({ id: 'generic.ms' }),
      value: 'Ms',
    },
    {
      label: intl.formatMessage({ id: 'generic.dr' }),
      value: 'Dr',
    },
    {
      label: intl.formatMessage({ id: 'generic.prof' }),
      value: 'Prof',
    },
  ];

  if (intl.locale === 'en') {
    const index = 2;
    return [
      ...baseSalutations.slice(0, index),
      {
        label: intl.formatMessage({ id: 'generic.female' }),
        value: 'Mrs',
      },
      ...baseSalutations.slice(index),
    ];
  }

  return baseSalutations;
};

export const getInitialResidentialData = ({
  residentialDataFromStorage,
  bookingPrimaryGuestData,
}: {
  residentialDataFromStorage?: ResidentialAddressStayType | null;
  bookingPrimaryGuestData?: StayPrimaryGuestType;
}) => {
  return {
    addressLine1:
      bookingPrimaryGuestData?.residentialAddress.addressLine1 ||
      residentialDataFromStorage?.addressLine1 ||
      '',
    postalCode:
      bookingPrimaryGuestData?.residentialAddress.postalCode ||
      residentialDataFromStorage?.postalCode ||
      '',
    city:
      bookingPrimaryGuestData?.residentialAddress.city ||
      residentialDataFromStorage?.city ||
      '',
    countryCode:
      bookingPrimaryGuestData?.residentialAddress.countryCode ||
      residentialDataFromStorage?.countryCode ||
      GERMAN_COUNTRY_CODE,
  };
};

export const getPersonalDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    title: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    gender: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    membershipNumber: yup.string(),
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    middleInitial: yup.string(),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    phone: Yup.phone,
    email: Yup.email,
  });

export const getResidentialDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    addressLine1: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    postalCode: Yup.zipCode.required(
      intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })
    ),
    city: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    countryCode: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getInvoiceDataEditSchema = ({
  Yup,
  intl,
}: {
  Yup: any;
  intl: IntlShape;
}) =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    email: Yup.email,
    vat: yup.string(),
    addressLine1: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    postalCode: Yup.zipCode.required(
      intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })
    ),
    city: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    countryCode: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getIdentificationDataEditSchema = ({
  intl,
}: {
  intl: IntlShape;
}) =>
  yup.object().shape({
    type: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthPlace: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    identificationIssuingAuthority: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    documentNumber: yup.string().when('type', (type, schema) =>
      typeof type === 'string' && type === 'PassportNumber'
        ? schema
            .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
            .min(
              6,
              intl.formatMessage({
                id: MIN_LENGTH_ERROR,
                defaultMessage: 'Minimum length is 6 characters',
              })
            )
            .max(
              9,
              intl.formatMessage({
                id: MAX_LENGTH_ERROR,
                defaultMessage: 'Maximum length is 9 characters',
              })
            )
            .matches(
              /^[\dA-Z]{6,9}$/,
              intl.formatMessage({
                id: INVALID_FORMAT_ERROR,
                defaultMessage: 'Invalid passport number format',
              })
            )
        : schema
            .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
            .min(
              6,
              intl.formatMessage({
                id: MIN_LENGTH_ERROR,
                defaultMessage: 'Minimum length is 6 characters',
              })
            )
            .max(
              14,
              intl.formatMessage({
                id: MAX_LENGTH_ERROR,
                defaultMessage: 'Maximum length is 14 characters',
              })
            )
            .matches(
              /^[\dA-Z]{6,14}$/,
              intl.formatMessage({
                id: INVALID_FORMAT_ERROR,
                defaultMessage: 'Invalid ID number format',
              })
            )
    ),
    issueDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    expireDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getAdditionalGuestSchema = ({ intl }: { intl: IntlShape }) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    middleInitial: yup.string(),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    nationality: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    birthPlace: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    type: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    documentNumber: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    issueDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
    expireDate: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  });

export const getSelectedResidentalAddress = (
  storedData: StayUserDataType | null
) =>
  storedData?.residentialData?.find(
    residentialAddress => residentialAddress.isSelected
  );

export const getNonSelectedResidentialAddresses = (
  storedData: StayUserDataType | null
) => {
  const selectedResidentialData = getSelectedResidentalAddress(storedData);
  return (
    storedData?.residentialData?.filter(address =>
      selectedResidentialData
        ? address.id !== selectedResidentialData?.id
        : false
    ) ?? []
  );
};

export const getSalutationToDisplay = ({
  title,
  intl,
}: {
  title?: string;
  intl: IntlShape;
}) =>
  getPersonalDataSalutationOptions(intl).find(
    salutation => salutation.value === title
  )?.label;

export const getGenderToDisplay = ({
  gender,
  intl,
}: {
  gender?: string;
  intl: IntlShape;
}) =>
  getPersonalDataGenderOptions(intl).find(
    salutation => salutation.value === gender
  )?.label;

export const getPersonalDataRows = (
  storedData: StayUserDataType | null,
  intl: IntlShape
) => [
  {
    label: intl.formatMessage({
      id: 'generic.gender',
    }),
    value: getGenderToDisplay({
      gender: storedData?.personalData.gender,
      intl,
    }),
  },
  {
    label: intl.formatMessage({
      id: 'generic.salutation',
    }),
    value: getSalutationToDisplay({
      title: storedData?.personalData.title,
      intl,
    }),
  },
  {
    label: intl.formatMessage({
      id: 'generic.wyndhamMembershipNumber',
    }),
    value: storedData?.personalData.membershipNumber || '-',
  },
  {
    label: intl.formatMessage({
      id: 'generic.firstName',
    }),
    value: storedData?.personalData.firstName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.middleInitial',
    }),
    value: storedData?.personalData.middleInitial || '-',
  },
  {
    label: intl.formatMessage({
      id: 'generic.lastName',
    }),
    value: storedData?.personalData.lastName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.phone',
    }),
    value: storedData?.personalData.phone,
  },
  {
    label: intl.formatMessage({
      id: 'generic.email',
    }),
    value: storedData?.personalData.email,
  },
  {
    label: intl.formatMessage({
      id: 'generic.birthday',
    }),
    value: storedData?.personalData.birthDate,
  },
  {
    label: intl.formatMessage({
      id: 'generic.nationality',
    }),
    value: storedData?.personalData.nationality,
    noMargin: true,
  },
];

export const getResidentialAddressDataRows = (
  intl: IntlShape,
  address?: ResidentialAddressStayType
) => [
  {
    label: intl.formatMessage({
      id: 'generic.addressLine1',
    }),
    value: address?.addressLine1 || '',
  },
  {
    label: intl.formatMessage({
      id: 'generic.city',
    }),
    value: `${address?.postalCode} ${address?.city}` || '',
  },
  {
    label: intl.formatMessage({
      id: 'generic.country',
    }),
    value: address?.countryCode || '',
    noMargin: true,
  },
];

export const getInvoiceAddressDataRows = (
  intl: IntlShape,
  address?: InvoiceAddressStayType
) => [
  {
    label: intl.formatMessage({
      id: 'generic.companyName',
    }),
    value: address?.companyName,
  },
  {
    label: intl.formatMessage({
      id: 'generic.email',
    }),
    value: address?.email,
  },
  {
    label: intl.formatMessage({
      id: 'generic.vat',
    }),
    value: address?.vat,
  },
  {
    label: intl.formatMessage({
      id: 'generic.addressLine1',
    }),
    value: address?.addressLine1,
  },
  {
    label: intl.formatMessage({
      id: 'generic.city',
    }),
    value: `${address?.postalCode} ${address?.city}`,
  },
  {
    label: intl.formatMessage({
      id: 'generic.country',
    }),
    value: address?.countryCode,
    noMargin: true,
  },
];

export const getIdentityTypeOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage({ id: 'generic.passport' }),
    value: IdentificationTypeEnum.PASSPORT_NUMBER,
    default: true,
  },
  {
    label: intl.formatMessage({ id: 'generic.idNumber' }),
    value: IdentificationTypeEnum.ID_NUMBER,
  },
];

export const getInitialIdentityData = (
  storedData: StayUserDataType | null,
  intl: IntlShape
) =>
  storedData?.personalData?.identityDocument
    ? {
        ...storedData?.personalData?.identityDocument,
        type:
          storedData?.personalData?.identityDocument.type ||
          getIdentityTypeOptions(intl).find(option => option.default)?.value,
      }
    : {
        type: getIdentityTypeOptions(intl).find(option => option.default)
          ?.value,
      };

export const getInitialGuestData = (guest?: StayAdditionalGuestType) => {
  return {
    ...guest,
    birthDate: guest?.birthDate,
  };
};

export const getBusinessFolio = (folios?: FolioItemType[]) => {
  if (!folios) return null;
  return folios.find(folio => folio.isBusinessFolio === true) || folios.at(0);
};

export const getIsBeforeArrivalDate = ({
  arrivalDate,
}: {
  arrivalDate: Dayjs;
}) => dayjs(arrivalDate).isBefore(dayjs());

export const getIsCheckoutPossible = ({
  booking,
  dateFormat,
}: {
  booking?: FolioEnrichedStayBookingType | null;
  dateFormat: string;
}) =>
  // eslint-disable-next-line sonarjs/no-redundant-boolean
  true ||
  dayjs(booking?.departureDate).format(dateFormat) ===
    dayjs().format(dateFormat);

export const getReservationStatus = ({
  intl,
  booking,
}: {
  intl: IntlShape;
  booking?: FolioEnrichedStayBookingType | null;
}) => {
  if (!booking?.status) return '';

  const hasCompletedMeldescheinBeforeCheckin =
    booking.status === HotelReservationStatusEnum.CONFIRMED &&
    booking?.meldeschein?.uuid;

  const hasCompletedMeldescheinAndPaidDepositBeforeCheckin =
    booking.status === HotelReservationStatusEnum.CONFIRMED &&
    booking?.meldeschein?.uuid &&
    booking.folioResponse?.nonCapturePayment?.uuid;

  if (hasCompletedMeldescheinAndPaidDepositBeforeCheckin) {
    return intl.formatMessage({
      id: 'hotelReservationStatus.preCheckinComplete',
    });
  }

  if (hasCompletedMeldescheinBeforeCheckin) {
    return intl.formatMessage({
      id: 'hotelReservationStatus.meldescheinComplete',
    });
  }
  return intl.formatMessage({
    id: `hotelReservationStatus.${booking?.status}`,
  });
};

export const shouldRedirectToHome = ({
  discoverId,
  isUnknownUser,
  isPMSconnected,
  selectedReservationReference,
}: {
  discoverId: string;
  isUnknownUser: boolean;
  isPMSconnected: boolean;
  selectedReservationReference?: string | null;
}) => {
  const rootPath = `${STAY_PATH}/${discoverId}`;
  return !(
    window.location.pathname === rootPath ||
    isUnknownUser ||
    selectedReservationReference ||
    !isPMSconnected
  );
};

export const getDisplayName = (
  booking: FolioEnrichedStayBookingType | null | undefined
) =>
  booking?.primaryGuest.firstName
    ? `${booking?.primaryGuest.firstName} ${booking?.primaryGuest.lastName}`
    : booking?.primaryGuest.lastName;

export const toLogin = (discoverId: string) => {
  window.localStorage.removeItem(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
  window.location.assign(`${STAY_PATH}/${discoverId}`);
};

export const onLogout = (discoverId?: string) => {
  window.localStorage.removeItem(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(STAY_SELECTED_RESERVATION_LOCAL_STORAGE_KEY);
  if (discoverId) {
    window.location.assign(`${STAY_PATH}/${discoverId}`);
  }
};

export const PRE_CHECKIN_START_STEP = 0;
export const PRE_CHECKIN_NATIONALITY_CHECK_STEP = 1;
export const PRE_CHECKIN_PERSONAL_DATA_STEP = 2;
export const PRE_CHECKIN_RESIDENTIAL_DATA_STEP = 3;
export const PRE_CHECKIN_PREFILLED_DATA_SUMMARY_STEP = 4;
export const PRE_CHECKIN_TRAVEL_REASON_STEP = 5;
export const PRE_CHECKIN_IDENTITY_DOCUMENT_STEP = 6;
export const PRE_CHECKIN_ADDITIONAL_GUESTS_STEP = 7;
export const PRE_CHECKIN_DATA_SUMMARY_STEP = 8;
export const PRE_CHECKIN_SIGNATURE_STEP = 9;
export const PRE_CHECKIN_PAYMENT_STEP = 10;

export const determinNextPrecheckinStep = ({
  currentStep,
  setNextStep,
  stayIdentityCheckEnabled,
  guestCount,
}: {
  currentStep: number;
  setNextStep: (step: number) => void;
  stayIdentityCheckEnabled?: boolean;
  guestCount?: number;
}) => {
  switch (currentStep) {
    case PRE_CHECKIN_RESIDENTIAL_DATA_STEP:
      // Skip prefilled data summary
      return setNextStep(PRE_CHECKIN_TRAVEL_REASON_STEP);
    case PRE_CHECKIN_TRAVEL_REASON_STEP:
      if (stayIdentityCheckEnabled) {
        // when identity check is enabled we move to the next step
        return setNextStep(PRE_CHECKIN_IDENTITY_DOCUMENT_STEP);
      }
      // Otherwise we need to check the additional guest count as that step might also be skipped
      if (guestCount && guestCount > 1) {
        return setNextStep(PRE_CHECKIN_ADDITIONAL_GUESTS_STEP);
      }
      return setNextStep(PRE_CHECKIN_DATA_SUMMARY_STEP);

    case PRE_CHECKIN_IDENTITY_DOCUMENT_STEP:
      if (guestCount && guestCount > 1) {
        return setNextStep(PRE_CHECKIN_ADDITIONAL_GUESTS_STEP);
      }
      return setNextStep(PRE_CHECKIN_DATA_SUMMARY_STEP);

    default:
      return setNextStep(currentStep + 1);
  }
};

export const determinBackPrecheckinStep = ({
  currentStep,
  setNextStep,
  stayIdentityCheckEnabled,
  guestCount,
}: {
  currentStep: number;
  setNextStep: (step: number) => void;
  stayIdentityCheckEnabled?: boolean;
  guestCount?: number;
}) => {
  switch (currentStep) {
    case PRE_CHECKIN_ADDITIONAL_GUESTS_STEP:
      if (stayIdentityCheckEnabled) {
        return setNextStep(PRE_CHECKIN_IDENTITY_DOCUMENT_STEP);
      }
      return setNextStep(PRE_CHECKIN_TRAVEL_REASON_STEP);

    case PRE_CHECKIN_DATA_SUMMARY_STEP:
      if (guestCount && guestCount > 1) {
        return setNextStep(PRE_CHECKIN_ADDITIONAL_GUESTS_STEP);
      }
      if (stayIdentityCheckEnabled) {
        return setNextStep(PRE_CHECKIN_IDENTITY_DOCUMENT_STEP);
      }
      return setNextStep(PRE_CHECKIN_TRAVEL_REASON_STEP);

    default:
      return setNextStep(currentStep - 1);
  }
};

export const getPossibleRedirectActionForReservationFromUrl = ({
  booking,
}: {
  booking?: StayBookingType | undefined | null;
}): string => {
  if (!booking) return '';
  const dateFormat =
    getLanguage() === 'de' ? germanDateStringFormat : 'YYYY-DD-MM';
  const userCanPreCheckin =
    booking.status === HotelReservationStatusEnum.CONFIRMED &&
    !booking?.meldeschein?.uuid;

  const checkoutAllowed = !!booking?.actions.find(
    action => action.action === 'CheckOut' && action.isAllowed
  );

  const userCanCheckout =
    checkoutAllowed &&
    dayjs(booking?.departureDate).format(dateFormat) ===
      dayjs().format(dateFormat);

  if (userCanPreCheckin || userCanCheckout) {
    return '/reservations';
  }

  return '';
};
