import { Colors } from './colors';

export const primaryStyle = {
  mainBorder: Colors.skyblue_100,
  mainBackground: Colors.skyblue_100,
  focusBorder: Colors.blue_400,
  focusBackground: Colors.blue_400,
  hoverBorder: Colors.blue_400,
  hoverBackground: Colors.blue_400,
  disabledBorder: Colors.blue_200,
  disabledBackground: Colors.blue_200,
  color: Colors.black_100,
};

export const secondaryStyle = {
  mainBorder: Colors.skyblue_100,
  mainBackground: 'transparent',
  focusBorder: Colors.blue_400,
  focusBackground: 'transparent',
  hoverBorder: Colors.blue_400,
  hoverBackground: 'transparent',
  disabledBorder: Colors.blue_200,
  disabledBackground: 'transparent',
  color: Colors.white_100,
  disabledColor: Colors.grey_300,
};

export const secondaryDarkStyle = {
  mainBorder: Colors.skyblue_100,
  mainBackground: 'transparent',
  focusBorder: Colors.blue_400,
  focusBackground: 'transparent',
  hoverBorder: Colors.blue_400,
  hoverBackground: 'transparent',
  disabledBorder: Colors.grey_300,
  disabledBackground: 'transparent',
  color: Colors.black_100,
  disabledColor: Colors.grey_300,
};

export const whiteStyle = {
  mainBorder: Colors.skyblue_100,
  mainBackground: Colors.white_100,
  focusBorder: Colors.blue_400,
  focusBackground: Colors.blue_400,
  hoverBorder: Colors.blue_400,
  hoverBackground: Colors.blue_400,
  disabledBorder: Colors.blue_400,
  disabledBackground: Colors.blue_400,
  color: Colors.black_100,
};

export const blackStyle = {
  mainBorder: Colors.black_100,
  mainBackground: Colors.black_100,
  focusBorder: Colors.black_100,
  focusBackground: Colors.black_100,
  hoverBorder: Colors.black_100,
  hoverBackground: Colors.black_100,
  disabledBorder: Colors.blue_400,
  disabledBackground: Colors.blue_400,
  color: Colors.white_100,
};
