import { css } from 'styled-components';

import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'constants/environment';

const createMedia = (mediaQuery: string) => {
  return (styles: any) => css`
    @media ${mediaQuery} {
      ${styles}
    }
  `;
};

const device = {
  mobile: `(max-width: ${MOBILE_BREAKPOINT}px)`,
  tablet: `(max-width: ${TABLET_BREAKPOINT}px)`,
};

export const Media = {
  mobile: createMedia(device.mobile),
  tablet: createMedia(device.tablet),
};
