import platform from 'platform';

import { GOOGLE_DOWNLOAD_LINK, APPLE_DOWNLOAD_LINK } from 'constants/links';

const { userAgent } = navigator;

export const isAppleProduct = /iPad|iPhone|iPod|Mac/.test(userAgent);

export const isAndroid = /android/i.test(userAgent);

export const IS_PRODUCTION = window.location.hostname === 'app.luca-app.de';

export const extractSubdomain = (hostname: string): string | null => {
  const subdomainPattern = /^app-(.*?)\.luca-app\.de$/;
  const match = hostname.match(subdomainPattern);
  return match ? match[1] : null;
};

const detectIOSOrSafari = (): boolean => {
  // https://stackoverflow.com/q/9038625/3569421
  if (
    (navigator.userAgent.includes('Chrome') &&
      navigator.userAgent.includes('Safari')) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
    return true;
  switch (navigator.platform) {
    case 'iPad Simulator':
    case 'iPhone Simulator':
    case 'iPod Simulator':
    case 'iPad':
    case 'iPhone':
    case 'iPod':
      return true;
    default:
      return false;
  }
};

const isIOSOrSafari = detectIOSOrSafari();

export const getAppDownloadLink = () => {
  return isAppleProduct ? APPLE_DOWNLOAD_LINK : GOOGLE_DOWNLOAD_LINK;
};
// eslint-disable-next-line complexity
const supportsWebRTC = (name?: string, version?: string, osName?: string) => {
  // on iOS only safari supports webRTC
  if (
    osName === 'iOS' &&
    name?.toLowerCase() !== 'mobile safari' &&
    name?.toLowerCase() !== 'safari'
  ) {
    return false;
  }
  // list of supported  Version from https://caniuse.com/stream
  let major = 0;
  if (version) {
    major = Number.parseInt(version, 10);
  }

  switch (name?.toLowerCase()) {
    case 'chrome':
    case 'chromium':
    case 'chrome mobile': {
      return major >= 53;
    }
    case 'firefox': {
      return major >= 42;
    }
    case 'opera': {
      return major >= 40;
    }
    case 'edge': {
      return major >= 12;
    }
    case 'safari':
    case 'mobile safari': {
      return major >= 11;
    }
    case 'samsung internet': {
      return major >= 4;
    }
    default: {
      return false;
    } // Not officially supported browser.
  }
};

export const hasMobileCamAccess = () => {
  const { name, version, os } = platform;
  // @ts-ignore family is defined
  const { family: osName } = os;

  const isWebRTCsupported = supportsWebRTC(name, version, osName);

  return isWebRTCsupported || false;
};

export const getDeviceHeight = () => {
  let heightCss = window.innerHeight;

  if (document.documentElement && 'clientWidth' in document.documentElement) {
    heightCss = document.documentElement.clientHeight;
  }

  if (isIOSOrSafari) {
    let bodyRect: DOMRect | null = null;

    // Another act out of desperation...
    if (
      document.documentElement &&
      'getBoundingClientRect' in document.documentElement
    )
      bodyRect = document.documentElement.getBoundingClientRect();
    else if ('getBoundingClientRect' in document.body)
      bodyRect = document.body.getBoundingClientRect();

    if (bodyRect) {
      heightCss = bodyRect.bottom - bodyRect.top;
    }
  }
  return heightCss;
};

export enum UserDeviceOS {
  AndroidPhone = 'AndroidPhone',
  Iphone = 'Iphone',
  Other = 'Other',
}

export const getUserDeviceOS = () => {
  const isIphone = platform.product === 'iPhone';

  const isAndroidPhone =
    /mobile/i.test(userAgent) &&
    !/tablet/i.test(userAgent) &&
    /android/i.test(userAgent);

  if (isIphone) {
    return UserDeviceOS.Iphone;
  }
  if (isAndroidPhone) {
    return UserDeviceOS.AndroidPhone;
  }

  return UserDeviceOS.Other;
};

export const getNonDeeplinkedHost = () => {
  const { hostname, port } = window.location;
  if (hostname.startsWith('app.') || hostname.startsWith('app-')) {
    const modifiedHost = hostname.replace(/^app(\.|-)/, 'l$1');
    return port ? `${modifiedHost}:${port}` : modifiedHost;
  }
  return port ? `${hostname}:${port}` : hostname;
};
