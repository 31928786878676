import { notification } from 'antd';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

export const useNotifications = () => {
  const intl = useIntl();

  const infoMessage = (
    description: string,
    message?: string,
    messageValues = {},
    descriptionValues = {}
  ) =>
    notification.info({
      message: intl.formatMessage(
        {
          id: message || 'general.notification.infoTitle',
        },
        { ...messageValues }
      ),
      description:
        description &&
        intl.formatMessage(
          {
            id: description,
          },
          { ...descriptionValues }
        ),
    });

  const errorMessage = (
    description: string,
    message?: string,
    descriptionValues = {},
    messageValues = {}
  ) =>
    notification.error({
      message: intl.formatMessage(
        {
          id: message || 'general.notification.errorTitle',
        },
        { ...messageValues }
      ),
      description:
        description &&
        intl.formatMessage(
          {
            id: description,
          },
          { ...descriptionValues }
        ),
      duration: 5,
    });

  const successMessage = (
    description: string,
    message?: string,
    descriptionValues = {},
    messageValues = {}
  ) =>
    notification.success({
      message: intl.formatMessage(
        {
          id: message || 'general.notification.successTitle',
        },
        { ...messageValues }
      ),
      description:
        description &&
        intl.formatMessage(
          {
            id: description,
          },
          { ...descriptionValues }
        ),
      duration: 5,
    });

  const customNotification = (
    type: 'info' | 'success' | 'error',
    message: ReactNode,
    description: ReactNode,
    icon?: React.ReactNode,
    style?: React.CSSProperties,
    duration = 0
  ) => {
    notification[type]({
      message,
      description,
      icon,
      duration,
      style,
      className: 'custom-luca-notification',
    });
  };

  return { infoMessage, errorMessage, successMessage, customNotification };
};
