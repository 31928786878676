import { useMemo } from 'react';
import { createContainer } from 'unstated-next';
import { useParams, useSearchParams } from 'react-router-dom';
import { DiscoverGroupContainerTypes } from 'types';
import {
  useGetDiscoverLocationGroup,
  useGetDiscoverPaymentSettingsV3,
  useGetLocationGroupSettingsByLocationId,
} from 'hooks';

const useSelfCheckoutContainer = (): DiscoverGroupContainerTypes => {
  const { discoverId, locationId } = useParams();
  const [searchParameters] = useSearchParams();

  const fallbackDiscoverId = useMemo(
    () => searchParameters.get('discoverId'),
    [searchParameters]
  );

  const { data: discoverGroup, isLoading: isDiscoverGroupLoading } =
    useGetDiscoverLocationGroup(discoverId || fallbackDiscoverId);
  const { data: groupSettings, isLoading: isGroupSettingsLoading } =
    useGetLocationGroupSettingsByLocationId(locationId);

  const {
    data: paymentDiscoverSettings,
    isLoading: isPaymentDiscoverSettingsLoading,
    error: paymentDiscoverSettingsError,
  } = useGetDiscoverPaymentSettingsV3(discoverId || fallbackDiscoverId);

  return {
    ...discoverGroup,
    ...groupSettings?.theme,
    locationName: discoverGroup?.name,
    paymentLimits: {
      minInvoiceAmount: paymentDiscoverSettings?.minInvoiceAmount || null,
      maxInvoiceAmount: paymentDiscoverSettings?.maxInvoiceAmount || null,
      maxTipAmount: paymentDiscoverSettings?.maxTipAmount || null,
    },
    paymentDiscoverSettingsError,
    isPaymentDiscoverSettingsLoading,
    isGroupSettingsLoading,
    isDiscoverGroupLoading,
  };
};

export const SelfCheckoutContainer = createContainer(useSelfCheckoutContainer);
