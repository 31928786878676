import { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { useMountEffect } from '@react-hookz/web';
import { PreorderPaymentContainerTypes } from 'types';
import { getPreorderFromLocalStorage, isValidUUID } from 'utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { LUCA_PAY_ENTRY_URL } from 'constants/sessionStorage';
import { LUCA_PAY_PATH, LUCA_PREORDER_ORDER_ROUTE } from 'constants/routes';
import {
  useGetBundle,
  useGetDiscoverPaymentSettingsV3,
  useGetLocation,
  useGetMeProfile,
} from 'hooks';
import { useConsumerContext } from 'context';

const usePreorderPaymentContainer = (): PreorderPaymentContainerTypes => {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const { trackEvent } = useMatomo();

  const referer = searchParameters.get('referer');
  const consumerAccessToken = searchParameters.get('accessToken');
  const { consumerData } = useConsumerContext();
  const storedData = getPreorderFromLocalStorage();

  useMountEffect(() => {
    trackEvent({
      category: 'PayWebapp',
      action: `Luca Preorder: Start preorder loading for discoverId: ${storedData?.discoverId}`,
    });
    // Store current url in local storage to get it back in case of Rapyd-Cancel
    window.sessionStorage.setItem(
      LUCA_PAY_ENTRY_URL,
      JSON.stringify({ lucaPayEntryURL: window.location.href })
    );
  });

  useEffect(() => {
    if (!bundleId || !isValidUUID(bundleId)) {
      navigate(LUCA_PAY_PATH);
      throw new Error('PreorderPayments: bundleId not set or invalid');
    }
  }, [bundleId, navigate]);

  const {
    data: bundle,
    error: bundleError,
    isLoading: isBundleLoading,
  } = useGetBundle({ consumerAccessToken, consumerData });

  const {
    data: paymentSettings,
    error: paymentSettingsError,
    isSuccess: isPaymentSettingsLoaded,
    isLoading: isPaymentSettingsLoading,
  } = useGetDiscoverPaymentSettingsV3(bundle?.locationDiscoverId);

  const {
    data: locationInfo,
    error: locationInfoError,
    isLoading: isLocationInfoLoading,
  } = useGetLocation(
    bundle?.order[0]?.locationId || bundle?.reservations[0]?.locationId
  );

  const { data: consumerProfile, isLoading: isProfileLoading } =
    useGetMeProfile({
      consumerData,
    });

  const hasError =
    !!locationInfoError || !!paymentSettingsError || !!bundleError;

  const paymentsDisabled =
    isPaymentSettingsLoaded && !paymentSettings?.paymentActive;

  if (hasError) {
    navigate(LUCA_PREORDER_ORDER_ROUTE);
    throw new Error('PreorderPayments: requests errored');
  }

  return {
    bundle,
    bundleError,
    isBundleLoading,
    paymentSettings,
    paymentSettingsError,
    isPaymentSettingsLoaded,
    isPaymentSettingsLoading,
    location: locationInfo,
    isLocationInfoLoading,
    hasError,
    paymentsDisabled,
    referer,
    consumerAccessToken,
    consumerProfile,
    isProfileLoading,
  };
};

export const PreorderPaymentContainer = createContainer(
  usePreorderPaymentContainer
);
