/* eslint-disable max-lines */
import { IntlShape } from 'react-intl';
import { styled } from 'styled-components';
import { useDeepCompareMemo } from '@react-hookz/web';
import dayjs, { Dayjs } from 'dayjs';

import {
  ArmchairIcon,
  BarIcon,
  BagIcon,
  TableIcon,
  TallTableIcon,
} from 'assets/icons';
import {
  DISABLED_OCCURRENCE_LIST,
  PREMIERE_SHOW_OCCURRENCE_IDS,
  ITEM_PIDS_WITH_GEDECK,
} from 'constants/lucaPreorder';
import {
  GroupedLocationType,
  GroupedLocationsByFloorsType,
  EventTimesType,
  LocalStoragePreorderData,
  getBundleResponseSchema,
  PreorderLocationsResponseType,
  BundleOrdersType,
  BundleReservationType,
  PreorderEventTimesType,
  SlotsType,
  ShowType,
  FloorPlan,
  GetOpenTablesForSlotsResponseType,
  GroupedLocationOptionType,
  DenormalizedMenuItemType,
  BasketType,
} from 'types';

const StyledTallTableIcon = styled(TallTableIcon)<{ $color?: string }>`
  > path {
    fill: ${({ $color }) => $color};
  }
`;

const StyledTableIcon = styled(TableIcon)<{ $color?: string }>`
  > path {
    fill: ${({ $color }) => $color};
  }
`;

const StyledBagIcon = styled(BagIcon)<{ $color?: string }>`
  > path {
    fill: ${({ $color }) => $color};
  }
`;

const StyledBarIcon = styled(BarIcon)<{ $color?: string }>`
  > path {
    fill: ${({ $color }) => $color};
  }
`;

const StyledArmchairIcon = styled(ArmchairIcon)<{ $color?: string }>`
  > path {
    fill: ${({ $color }) => $color};
  }
`;

const PREORDER_LOCAL_STORAGE_KEY = 'preorderData';

export const getPreorderFromLocalStorage =
  (): LocalStoragePreorderData | null => {
    const storageItem = window.localStorage.getItem(PREORDER_LOCAL_STORAGE_KEY);
    return storageItem
      ? (JSON.parse(storageItem) as LocalStoragePreorderData)
      : null;
  };

export const setPreorderFromLocalStorage = (value: any) => {
  const storageItem = window.localStorage.getItem(PREORDER_LOCAL_STORAGE_KEY);
  const storedObject = storageItem ? JSON.parse(storageItem) : {};

  window.localStorage.setItem(
    PREORDER_LOCAL_STORAGE_KEY,
    JSON.stringify({ ...storedObject, ...value })
  );
};

export const removePreorderFromLocalStorage = () =>
  window.localStorage.removeItem(PREORDER_LOCAL_STORAGE_KEY);

export const startOver = () => {
  removePreorderFromLocalStorage();
  window.location.reload();
};

export const getPossibleShowsToVisit = ({
  storedData,
  eventTimes,
}: {
  storedData: LocalStoragePreorderData | null;
  eventTimes: EventTimesType;
}) => {
  const formattedSelectedDate = dayjs(storedData?.selectedDate).format(
    'YYYY-MM-DD'
  );
  return (
    eventTimes.calendarTimes
      .find(calendarTime => calendarTime.date === formattedSelectedDate)
      ?.shows.filter(show => !DISABLED_OCCURRENCE_LIST.includes(show.uuid)) ||
    []
  );
};

export const getOptionMappings = (textColor?: string) => [
  {
    type: 'STANDING_TABLE',
    icon: <StyledTallTableIcon $color={textColor} />,
    localeKey: 'opera.locationSelection.option.standingTable',
  },
  {
    type: 'RESTAURANT',
    icon: <StyledTableIcon $color={textColor} />,
    localeKey: 'opera.locationSelection.option.restaurant',
  },
  {
    type: 'LOUNGE',
    icon: <StyledArmchairIcon $color={textColor} />,
    localeKey: 'opera.locationSelection.option.lounge',
  },
  {
    type: 'BAR',
    icon: <StyledBarIcon $color={textColor} />,
    localeKey: 'opera.locationSelection.option.bar',
  },
  {
    type: 'PICKUP',
    icon: <StyledBagIcon $color={textColor} />,
    localeKey: 'opera.locationSelection.option.pickup',
  },
];

const getLocationsByCode = (
  preorderLocations: PreorderLocationsResponseType,
  code: string,
  intl: IntlShape,
  floorPlan: FloorPlan
): {
  name: string;
  locations: PreorderLocationsResponseType;
  imageUrl: string;
} => {
  const locations = preorderLocations.filter(location =>
    location.attributes.some(
      attribute => attribute.type === 'LOCATION' && attribute.code === code
    )
  );
  return {
    name: intl.formatMessage({ id: floorPlan[code].name }),
    locations,
    imageUrl: floorPlan[code].imageUrl || '',
  };
};

const getGroupedLocationsByFloor = (
  floors: {
    name: string;
    locations: PreorderLocationsResponseType;
    imageUrl: string;
  }[],
  textColor?: string
): GroupedLocationsByFloorsType =>
  floors.map(floor => {
    const groupedLocations: GroupedLocationType[] = [];

    floor.locations.forEach(location => {
      const locationGroupedName = location.attributes.find(
        attribute => attribute.type === 'GROUPED_NAME'
      )?.name;

      const isInGroupedLocations = groupedLocations.some(
        groupLocation => groupLocation.locationName === locationGroupedName
      );
      const serviceTypeCode = location.attributes.find(
        attribute => attribute.type === 'SERVICE_TYPE'
      )?.code;
      const serviceTypeLocal = location.attributes.find(
        attribute => attribute.type === 'SERVICE_TYPE'
      )?.name;

      if (!isInGroupedLocations) {
        groupedLocations.push({
          locationName: locationGroupedName,
          options: [
            {
              locationId: location.locationId,
              icon: getOptionMappings(textColor).find(mapping =>
                serviceTypeCode?.startsWith(mapping.type)
              )?.icon,
              locale: serviceTypeLocal,
              key: serviceTypeCode,
            },
          ],
        });
      } else {
        const currentEntry = groupedLocations.find(
          groupLocation => groupLocation.locationName === locationGroupedName
        );
        currentEntry?.options.push({
          locationId: location.locationId,
          icon: getOptionMappings(textColor).find(mapping =>
            serviceTypeCode?.startsWith(mapping.type)
          )?.icon,
          locale: serviceTypeLocal,
          key: serviceTypeCode,
        });
      }
    });

    return {
      name: floor.name,
      locations: groupedLocations,
      imageUrl: floor.imageUrl,
    };
  });

export const getLocationsToChoose = ({
  preorderLocations,
  intl,
  show,
  floorPlan,
  textColor,
}: {
  preorderLocations: PreorderLocationsResponseType;
  intl: IntlShape;
  show?: ShowType;
  floorPlan: FloorPlan;
  textColor?: string;
}): GroupedLocationsByFloorsType => {
  let locations = preorderLocations;
  /*
  Special case for opera munich:
  If the selected show is a premier the "Ionischen Säle" are blocked for preordering
  Here we match for the location name because it is generated and the same in all envs (uuids would be different on different envs)
  */
  if (show?.uuid && PREMIERE_SHOW_OCCURRENCE_IDS.includes(show?.uuid)) {
    locations = preorderLocations.filter(
      location => location.locationName !== 'Ionischer Saal Nord Stehtische'
    );
  }
  /*
  Special case for theater munich:
  We initially set them up with to locations each having standing and seating options. Now they only want one location to also enable the shortflow in the location selection
  */
  locations = preorderLocations.filter(
    location =>
      location.locationName !== 'Pausenfoyer 1. Rang | links Sitzinseln' &&
      location.locationName !== 'Pausenfoyer 1. Rang | links Stehtische'
  );

  const floors = Object.keys(floorPlan).map(code =>
    getLocationsByCode(locations, code, intl, floorPlan)
  );

  const groupedLocationsPerFloor = getGroupedLocationsByFloor(
    floors,
    textColor
  );

  /*
  Special case for opera munich:
  We can't delete the locations for the lounges in Restaurant Ludwig since people already have booked them so we need to filter.
  */
  return groupedLocationsPerFloor.map(floor => {
    return {
      ...floor,
      locations: floor.locations.map(location => {
        if (location?.locationName?.startsWith('Ludwig')) {
          const filteredOptions = location.options.filter(
            option => option.key !== 'LOUNGE'
          );
          return { ...location, options: filteredOptions };
        }
        return location;
      }),
    };
  });
};

export const getTotalOrdersAmount = (
  bundle: getBundleResponseSchema | undefined | null
) => {
  let total = 0;
  bundle?.order.forEach(order => {
    total += order.invoiceAmount;
  });
  return total;
};

export const getIsCurrentTimeBeforePreorderDeadline = (
  showOrSlotStart: Dayjs,
  minimumTimeBeforeShowOrSlot: number
): boolean => {
  const currentTime = dayjs();

  const showOrSlotStartTime = dayjs(showOrSlotStart);

  const preorderDeadline = showOrSlotStartTime.subtract(
    minimumTimeBeforeShowOrSlot,
    'minute'
  );

  return currentTime.isBefore(preorderDeadline);
};

export const formatAndLocalizeMinimumPreorderTime = (
  minimalPreorderTime: number,
  intl: IntlShape
) =>
  minimalPreorderTime <= 60
    ? intl.formatMessage(
        { id: 'preorder.time.minutes' },
        { value: minimalPreorderTime }
      )
    : intl.formatMessage(
        { id: 'preorder.time.hours' },
        { value: Math.round(minimalPreorderTime / 60) }
      );

export const getLocationInfos = ({
  order,
  reservation,
  locationsToChoose,
}: {
  order: BundleOrdersType | null;
  reservation: BundleReservationType | null;
  locationsToChoose: GroupedLocationsByFloorsType;
}) => {
  const floorInfos = locationsToChoose.find(floor =>
    floor.locations.find(location =>
      location.options.some(
        option =>
          option.locationId === order?.locationId ||
          option.locationId === reservation?.locationId
      )
    )
  );

  const locationInfos = floorInfos?.locations.find(location =>
    location.options.some(
      option =>
        option.locationId === order?.locationId ||
        option.locationId === reservation?.locationId
    )
  );

  const optionInfos = locationInfos?.options.find(
    option =>
      option.locationId === order?.locationId ||
      option.locationId === reservation?.locationId
  );

  return {
    floorName: floorInfos?.name,
    locationName: locationInfos?.locationName,
    locationIcon: optionInfos?.icon,
    optionName: optionInfos?.locale,
    optionKey: optionInfos?.key,
  };
};

export const getInvoiceAmount = (preorders?: BundleOrdersType[] | undefined) =>
  preorders?.reduce((total, order) => total + order.invoiceAmount, 0) || 0;

export const getOrderedSlots = (
  slots: SlotsType[],
  eventTimes: PreorderEventTimesType
) => {
  const orderedSlots: SlotsType[] = [];

  const orderedEventTimes = eventTimes.slots.sort(
    (a, b) => dayjs(a.startsAt).unix() - dayjs(b.startsAt).unix()
  );
  orderedEventTimes.forEach(eventTime => {
    const correspondingSlot = slots.find(
      slot => slot.slotId === eventTime.uuid
    );
    if (correspondingSlot) {
      orderedSlots.push(correspondingSlot);
    }
  });

  return orderedSlots;
};

export const getOrdersAndReservationsPerSlot = (
  bundle: getBundleResponseSchema | undefined | null,
  eventTimes: PreorderEventTimesType | undefined
) => {
  if (!eventTimes || !bundle) return [];
  const slots: SlotsType[] = [];

  bundle.order.forEach(order => {
    const slotId = order.showSlot.uuid;

    const slotInSlots = slots.find(slot => slot.slotId === slotId);
    if (!slotInSlots) {
      slots.push({ slotId, orders: [order], reservation: null });
      return;
    }
    slotInSlots.orders?.push(order);
  });

  bundle.reservations.forEach(reservation => {
    const slotId = reservation.showSlot.uuid;

    const slotInSlots = slots.find(slot => slot.slotId === slotId);
    if (!slotInSlots) {
      slots.push({ slotId, orders: [], reservation });
      return;
    }
    slotInSlots.reservation = reservation;
  });

  return getOrderedSlots(slots, eventTimes);
};

export const canProceedPreorder = (
  bundle: getBundleResponseSchema | undefined | null
): boolean => {
  return (
    !!bundle?.order &&
    !!bundle?.reservations &&
    bundle.order.length > 0 &&
    bundle.order.length === bundle.reservations.length &&
    bundle.order.every(order =>
      bundle.reservations.some(
        reservation => reservation.uuid === order.reservationId
      )
    )
  );
};

export const getIsLocationFullyBooked = ({
  location,
  openTablesForSlots,
}: {
  location: GroupedLocationType;
  openTablesForSlots?: GetOpenTablesForSlotsResponseType | null;
}) => {
  const locationsForSlot = openTablesForSlots?.showOccurrenceSlots.find(
    showOccurrenceSlot =>
      showOccurrenceSlot.showOccurrenceSlotId ===
      getPreorderFromLocalStorage()?.selectedSlot?.uuid
  )?.locations;

  const relevantLocations = locationsForSlot?.filter(locationForSlot =>
    location.options.find(
      option => option.locationId === locationForSlot.locationId
    )
  );

  return relevantLocations?.every(
    relevantLocation => relevantLocation.openTables.length === 0
  );
};

export const getIsOptionFullyBooked = ({
  option,
  openTablesForSlots,
}: {
  option: GroupedLocationOptionType;
  openTablesForSlots?: GetOpenTablesForSlotsResponseType | null;
}) => {
  const locationsForSlot = openTablesForSlots?.showOccurrenceSlots.find(
    showOccurrenceSlot =>
      showOccurrenceSlot.showOccurrenceSlotId ===
      getPreorderFromLocalStorage()?.selectedSlot?.uuid
  )?.locations;

  const relevantLocation = locationsForSlot?.find(
    location => location.locationId === option.locationId
  );

  return relevantLocation?.openTables.length === 0;
};

export const getIsShortVersion = ({
  locationsToChoose,
}: {
  locationsToChoose: GroupedLocationsByFloorsType;
}) =>
  locationsToChoose &&
  locationsToChoose.length === 1 &&
  locationsToChoose[0].locations &&
  locationsToChoose[0].locations.length === 1;

export const getIsPreorderMaintenanceForDiscoverId = ({
  discoverId,
  featureFlags,
}: {
  discoverId?: string;
  featureFlags?: { [key: string]: boolean } | null;
}): boolean => {
  if (!discoverId || !featureFlags) return false;

  return Object.keys(featureFlags).some(
    key =>
      key === `preorder_maintenance_${discoverId}` && featureFlags[key] === true
  );
};

export const getIsPreorderBreakForDiscoverId = ({
  discoverId,
  featureFlags,
}: {
  discoverId?: string;
  featureFlags?: { [key: string]: boolean } | null;
}): boolean => {
  if (!discoverId || !featureFlags) return false;

  return Object.keys(featureFlags).some(
    key => key === `preorder_break_${discoverId}` && featureFlags[key] === true
  );
};

export const checkIfBasketHasItemWithGedeck = (
  basket: BasketType,
  items: DenormalizedMenuItemType[]
) => {
  const ITEM_UUIDS_WITH_GEDECK = new Set(
    items
      .filter(item => item.pid && ITEM_PIDS_WITH_GEDECK.includes(item.pid))
      .map(item => item.uuid)
  );

  return basket.some(basketItem => ITEM_UUIDS_WITH_GEDECK.has(basketItem.uuid));
};

export const useHasBasketItemWithGedeck = (
  basket: BasketType,
  items: DenormalizedMenuItemType[]
) => {
  return useDeepCompareMemo(() => {
    return checkIfBasketHasItemWithGedeck(basket, items);
  }, [basket, items]);
};

export const getIsLudwigZweiLocation = (locationName?: string | null) =>
  locationName?.startsWith('Ludwig Zwei Restaurant');

export const getIsRheingoldLocation = (locationName?: string | null) =>
  locationName?.startsWith('Rheingoldbar');

export const getIsOperaPromotionModalOpen = (locationName?: string | null) => {
  if (!locationName) return false;
  return !!(
    getIsLudwigZweiLocation(locationName) ||
    getIsRheingoldLocation(locationName)
  );
};
