import { useDeepCompareMemo } from '@react-hookz/web';

import { AVAILABLE_CAMPAIGNS } from 'constants/campaigns';
import { CompletePaymentCampaign, LocationGroupCampaignResponse } from 'types';

export const useGetHighestDiscountCampaign = (
  campaigns: LocationGroupCampaignResponse[] = []
): CompletePaymentCampaign | undefined => {
  return useDeepCompareMemo(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const minimalBufferTime = 60;

    const discountCampaigns = campaigns
      .map(campaign => campaign.paymentCampaign)
      .filter(
        (paymentCampaign): paymentCampaign is CompletePaymentCampaign =>
          paymentCampaign.name.startsWith(AVAILABLE_CAMPAIGNS.LUCA_DISCOUNT) &&
          typeof paymentCampaign.discountPercentage === 'number' &&
          typeof paymentCampaign.discountMaxAmount === 'number' &&
          currentTime >= paymentCampaign.startsAt &&
          (typeof paymentCampaign.endsAt !== 'number' ||
            currentTime + minimalBufferTime <= paymentCampaign.endsAt)
      )
      .sort((a, b) => b.discountPercentage - a.discountPercentage);

    return discountCampaigns[0];
  }, [campaigns]);
};
