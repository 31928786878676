export const GOOGLE_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=de.culture4life.luca';

export const APPLE_DOWNLOAD_LINK =
  'https://apps.apple.com/de/app/luca-app/id1531742708';

export const GENERAL_DOWNLOAD_LINK =
  'https://www.luca-app.de/download-link-redirect';

export const HELLO_EMAIL = 'hello@luca-app.de';

export const REPORT_FRAUD_EMAIL = 'abuse@luca-app.de';

export const IMPRINT_EN = 'https://luca-app.de/en/imprint/';

export const IMPRINT = 'https://www.luca-app.de/impressum/';

export const FAQ = 'https://www.luca-app.de/faq/';

export const PRIVACY_LINK = 'https://www.luca-app.de/webapp-privacy-policy/';

export const TERMS_CONDITIONS_LINK =
  'https://luca-app.de/app-terms-and-conditions/';

export const INSTALL_REDIRECT_LINK = 'https://app.luca-app.de/webapp/install';

export const TERMS_CONDITIONS_LINK_DE =
  'https://luca-app.de/app-terms-and-conditions/';

export const TERMS_CONDITIONS_LINK_EN =
  'https://luca-app.de/en/terms-of-use-apps/';

export const PRIVACY_LINK_DE = 'https://luca-app.de/app-privacy-policy/';

export const PRIVACY_LINK_EN =
  'https://luca-app.de/en/privacy-policy-luca-app/';
