import { Component, ErrorInfo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { ErrorIconSVG, LucaLogoBlackIconSVG } from 'assets/icons';
import { NormalText15Black } from 'components/general';

import {
  ContentWrapper,
  ErrorDescription,
  ErrorGraphic,
  ErrorHeadline,
  HeaderLogo,
  HeaderWrapper,
  StyledShowMoreLessButton,
  Wrapper,
  StyledDivider,
} from './ApplicationErrorPage.styled';

interface ErrorWrapperState {
  hasError: boolean;
  error?: unknown;
  errorInfo?: any;
  showError: boolean;
}

class ApplicationError extends Component<
  WrappedComponentProps,
  ErrorWrapperState
> {
  constructor(properties: WrappedComponentProps) {
    super(properties);
    this.state = { hasError: false, showError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true, error, errorInfo });
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, error, errorInfo, showError } = this.state;
    // @ts-ignore check later
    const { intl, children } = this.props;

    const toggleError = () => {
      this.setState({ showError: !showError });
    };

    if (hasError) {
      return (
        <Wrapper>
          <HeaderWrapper>
            <HeaderLogo src={LucaLogoBlackIconSVG} />
          </HeaderWrapper>
          <ContentWrapper>
            <ErrorHeadline>
              {intl.formatMessage({ id: 'error.headline' })}
            </ErrorHeadline>
            <ErrorDescription>
              {intl.formatMessage({ id: 'error.description' })}
            </ErrorDescription>
            <ErrorGraphic src={ErrorIconSVG} />
            <StyledDivider>
              <StyledShowMoreLessButton onClick={toggleError}>
                {intl.formatMessage({
                  id: showError ? 'generic.showLess' : 'generic.showMore',
                })}
              </StyledShowMoreLessButton>
            </StyledDivider>
            {showError && (
              <>
                <NormalText15Black
                  style={{
                    padding: '16px 0',
                    overflow: 'auto',
                    width: '100%',
                  }}
                  // @ts-ignore error has a name prop
                >{`${error.name}`}</NormalText15Black>
                <NormalText15Black
                  style={{
                    padding: '16px 0',
                    overflow: 'auto',
                    width: '100%',
                  }}
                >{`${error}`}</NormalText15Black>
                <NormalText15Black
                  style={{
                    padding: '16px 0',
                    overflow: 'auto',
                    width: '100%',
                  }}
                >{`${errorInfo?.componentStack}`}</NormalText15Black>
              </>
            )}
          </ContentWrapper>
        </Wrapper>
      );
    }

    return children;
  }
}
export const ApplicationErrorPage = injectIntl(ApplicationError);
