import { TableData } from 'types';

const base64UrlToBytes = (base64Url: string) => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let index = 0; index < rawLength; index++) {
    array[index] = raw.codePointAt(index) || 0;
  }
  return array;
};

const decodeUtf8 = (bytes: BufferSource | undefined) => {
  const decoder = new TextDecoder('utf-8');
  return decoder.decode(bytes);
};

export const decodeURI = (hash: string) => {
  const base64String = hash.slice(1);
  const bytes = base64UrlToBytes(base64String);
  const decodedData = decodeUtf8(bytes);
  return JSON.parse(decodedData);
};

const isUUID = (string: string) => {
  const regexExp = /^[\da-f]{8}(?:\b-[\da-f]{4}){3}\b-[\da-f]{12}$/gi;
  return regexExp.test(string);
};

const isCurrentHostname = (string: string) => {
  return window.location.hostname === string;
};

export const isValidLucaQrCode = (qrCodeContent: string) => {
  try {
    const url = new URL(qrCodeContent);
    const { pathname, hostname } = url;
    const pathChunks = pathname.split('/');
    return !!(
      pathChunks.length === 3 &&
      pathChunks[1] === 'webapp' &&
      isCurrentHostname(hostname) &&
      isUUID(pathChunks[2])
    );
  } catch {
    return false;
  }
};

const extractDataFromUrl = () => {
  const { hash } = window.location;
  if (!hash) return null;

  try {
    return decodeURI(hash);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const extractTableFromUrl = (): TableData | null => {
  const decodedData = extractDataFromUrl();
  if (decodedData === null || (!decodedData?.Table && !decodedData?.tableId))
    return null;

  // Support legacy QR codes
  if (decodedData.Table) {
    return { table: decodedData.Table };
  }
  return { tableId: decodedData.tableId };
};

export const extractIsHotelOrderingFromUrl = (): {
  isHotelOrdering: boolean;
} | null => {
  const decodedData = extractDataFromUrl();
  if (decodedData === null || !decodedData?.isHotelOrdering) {
    return null;
  }

  return { isHotelOrdering: decodedData.isHotelOrdering };
};

export const extractIsSelfCheckoutFromUrl = (): {
  isSelfCheckout: boolean;
} | null => {
  const decodedData = extractDataFromUrl();
  if (decodedData === null || !decodedData?.isSelfCheckout) {
    return null;
  }

  return { isSelfCheckout: decodedData.isSelfCheckout };
};

export const extractAdditionalInfoFromUrl = () => {
  const decodedData = extractDataFromUrl();
  if (decodedData === null || !decodedData?._) return null;
  const { _ } = decodedData;
  return _;
};
