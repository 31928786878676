import {
  SELF_CHECKOUT_API_KEY_LOCAL_STORAGE_KEY,
  SELF_CHECKOUT_TERMINAL_ID_LOCAL_STORAGE_KEY,
} from 'constants/localStorage';

export const getLinkApiKeyFromLocalStorage = (): string | null => {
  const storageItem = window.localStorage.getItem(
    SELF_CHECKOUT_API_KEY_LOCAL_STORAGE_KEY
  );
  return storageItem ? (JSON.parse(storageItem) as string) : null;
};

export const getTerminalIdFromLocalStorage = (): string | null => {
  const storageItem = window.localStorage.getItem(
    SELF_CHECKOUT_TERMINAL_ID_LOCAL_STORAGE_KEY
  );
  return storageItem ? (JSON.parse(storageItem) as string) : null;
};
