export const headers = {
  'Content-Type': 'application/json',
};

export class ApiError extends Error {
  status: number;

  response: Response;

  constructor(response: Response, message?: string) {
    super();
    this.response = response;
    this.status = response.status;
    this.message =
      message ||
      `Request to ${response.url} failed with status ${response.status}`;
  }
}
