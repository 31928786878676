/* eslint-disable camelcase */

// Basics
const white_100 = '#ffffff';
const black_15 = '#D4D4D4';
const black_50 = '#797A7B';
const black_80 = '#1A1A1A';
const black_100 = '#000000';

// Grey
const grey_100 = '#F5F5F6';
const grey_200 = '#E6E6E7';
const grey_300 = '#D2D2D5';
const grey_400 = '#A8A8AE';
const grey_500 = '#82828A';
const grey_600 = '#444450';

// Main Blue
const blue_100 = '#F7F8FF';
const blue_200 = '#E6E9FF';
const blue_300 = '#D7DEFF';
const blue_400 = '#AFBEFF';
const blue_500 = '#7D96FF';
const blue_600 = '#4041B0';
const blue_700 = '#202058';

// Aqua blue
const aquablue_100 = '#5486B8';

// Sky blue
const skyblue_100 = '#7d96ff';

// Sea blue
const seablue_50 = '#D7E1F2';
const seablue_100 = '#AFC3E6';

// Green
const green_100 = '#E1F0D8';
const green_200 = '#CCE6BE';
const green_300 = '#A5D28C';
const green_400 = '#80AC68';
const green_500 = '#468145';
const green_600 = '#196923';

// Orange
const orange_100 = '#FFDCC8';
const orange_200 = '#FFC39B';
const orange_300 = '#FF6946';
const orange_400 = '#F16704';

// Purple
const purple_100 = '#ECC8FF';
const purple_200 = '#D7A5F2';
const purple_300 = '#C078EA';
const purple_400 = '#8228B9';

// Pink
const pink_100 = '#FFD7E6';
const pink_200 = '#FAA5C8';
const pink_300 = '#FA82B4';

// Yellow
const yellow_100 = '#FFDC5F';

// Gold
const gold_100 = '#C8A22E';

export const Colors = {
  white_100,
  black_15,
  black_50,
  black_80,
  black_100,
  grey_100,
  grey_200,
  grey_300,
  grey_400,
  grey_500,
  grey_600,
  blue_100,
  blue_200,
  blue_300,
  blue_400,
  blue_500,
  blue_600,
  blue_700,
  aquablue_100,
  skyblue_100,
  seablue_50,
  seablue_100,
  green_100,
  green_200,
  green_300,
  green_400,
  green_500,
  green_600,
  orange_100,
  orange_200,
  orange_300,
  orange_400,
  yellow_100,
  gold_100,
  purple_100,
  purple_200,
  purple_300,
  purple_400,
  pink_100,
  pink_200,
  pink_300,
};
