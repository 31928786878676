import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';

import { defaultLightTheme } from 'constants/globalStyles';
import { useGetGroupSettingsByDiscoverId, useGetReceiptPayment } from 'hooks';

const useShopperReceiptsContainer = () => {
  const { discoverId } = useParams();

  const [searchParameters] = useSearchParams();

  const shopperReceiptsAccessToken = useMemo(
    () => searchParameters.get('accessToken'),
    []
  );

  const {
    data: payment,
    isLoading: isPaymentLoading,
    error: paymentError,
  } = useGetReceiptPayment({
    shopperReceiptsAccessToken,
  });

  const {
    data: groupSettings,
    isLoading: isGroupSettingsLoading,
    error: groupSettingsError,
  } = useGetGroupSettingsByDiscoverId({ discoverId });

  const theme = groupSettings?.theme
    ? { ...groupSettings?.theme }
    : { ...defaultLightTheme };

  return {
    theme,
    payment,
    paymentError,
    groupSettingsError,
    shopperReceiptsAccessToken,
    isGroupSettingsLoading,
    isPaymentLoading,
  };
};

export const ShopperReceiptsContainer = createContainer(
  useShopperReceiptsContainer
);
