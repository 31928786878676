const REFERER_REGEX = /^luca\/(ios|android)$/i;

export const getReferer = (
  searchParameters: URLSearchParams
): string | null => {
  const referer = searchParameters.get('referer');
  if (!referer) {
    return null;
  }
  if (referer.match(REFERER_REGEX)) {
    return referer;
  }
  return null;
};

export const hasNativeAppRefer = (
  searchParameters: URLSearchParams
): boolean => {
  // all allowed referers are currently native app referers
  return !!getReferer(searchParameters);
};

export const isAndroidReferer = (searchParameters: URLSearchParams) => {
  const referer = getReferer(searchParameters);

  return !!referer?.match(/android/i);
};
