import { useCallback, useEffect, useState } from 'react';
import { NetworkStatuses, NetworkStatusInformation } from './networkStatuses';

type NetworkStatus = NetworkStatuses.Online | NetworkStatuses.Offline;

export const useNetworkConnection = (
  networkStatusCallback: (networkStatus: NetworkStatusInformation) => void
) => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatusInformation>(
    () => ({
      isOnline: false,
      since: undefined,
    })
  );

  useEffect(() => {
    setNetworkStatus({
      isOnline: navigator.onLine,
      since: undefined,
    });
  }, []);

  const handleNetworkConnectionStatus = useCallback((status: NetworkStatus) => {
    setNetworkStatus(
      (previousNetworkConnectionStatus: NetworkStatusInformation) => ({
        ...previousNetworkConnectionStatus,
        isOnline: status === NetworkStatuses.Online,
        since: new Date().toISOString(),
      })
    );
  }, []);

  const handleStatus = useCallback(
    (status: NetworkStatus) => () => {
      handleNetworkConnectionStatus(status);
    },
    [handleNetworkConnectionStatus]
  );

  useEffect(() => {
    if (networkStatus.since) networkStatusCallback(networkStatus);

    const handleOnline = handleStatus(NetworkStatuses.Online);
    const handleOffline = handleStatus(NetworkStatuses.Offline);

    window.addEventListener(NetworkStatuses.Online, handleOnline);
    window.addEventListener(NetworkStatuses.Offline, handleOffline);

    return () => {
      window.removeEventListener(NetworkStatuses.Online, handleOnline);
      window.removeEventListener(NetworkStatuses.Offline, handleOffline);
    };
  }, [handleStatus, networkStatus, networkStatusCallback]);

  return networkStatus;
};
