import {
  BasketType,
  DenormalizedMenuItemType,
  SelectedItemType,
  SerializedOrderItemType,
} from 'types';

export const getTotalQuantity = (basket: BasketType): number =>
  basket.reduce(
    (total: number, item: SelectedItemType) => total + item.quantity,
    0
  );

export const generateUniqueBasketItemKey = (
  selectedItem: SerializedOrderItemType
) => {
  let key = selectedItem.uuid;
  if (selectedItem.selectCategoryId) {
    key += `_${selectedItem.selectCategoryId}`;
  }

  if (selectedItem.subItems && selectedItem.subItems.length > 0) {
    selectedItem.subItems.forEach(subItem => {
      key += `_${generateUniqueBasketItemKey(subItem)}`;
    });
  }
  return key;
};

export const getItemInBasket = (
  basket: BasketType,
  item: DenormalizedMenuItemType
) => {
  return basket.find(basketItem => basketItem.uuid === item.uuid);
};

export const getItemsInBasket = (
  basket: BasketType,
  item: SerializedOrderItemType
) => {
  return basket.filter(
    basketItem =>
      generateUniqueBasketItemKey(basketItem) ===
      generateUniqueBasketItemKey(item)
  ) as SerializedOrderItemType[];
};

export const getQuantityOfItemsWithDifferentSubItems = (
  basket: BasketType,
  item: DenormalizedMenuItemType
) => {
  const allItems = basket.filter(basketItem => basketItem.uuid === item.uuid);

  let total = 0;

  for (const basketItem of allItems) {
    if (basketItem.uuid === item.uuid) {
      total += basketItem.quantity;
    }
  }

  return total;
};

export const compareSubItems = (
  item1: SerializedOrderItemType,
  item2: SerializedOrderItemType
): boolean => {
  if (
    (!item1.subItems || item1.subItems.length === 0) &&
    (!item2.subItems || item2.subItems.length === 0)
  ) {
    return true; // Both items have no sub-items
  }
  if (
    (item1.subItems && !item2.subItems) ||
    (!item1.subItems && item2.subItems) ||
    item1.subItems?.length !== item2.subItems?.length
  ) {
    return false; // Mismatch in sub-items existence or count
  }
  // Recursively compare all sub-items
  for (let index = 0; index < item1.subItems!.length; index++) {
    const subItem1 = item1.subItems![index];
    const subItem2 = item2.subItems!.find(si => si.uuid === subItem1.uuid);
    if (!subItem2 || !compareSubItems(subItem1, subItem2)) {
      return false; // Mismatch found in sub-items
    }
  }
  return true; // All sub-items match
};

export const getBasketWithAddedItem = (
  item: SerializedOrderItemType,
  basket: BasketType
) => {
  let foundMatchingItem = false;

  const newBasket = basket.map(basketItem => {
    if (basketItem.uuid === item.uuid && compareSubItems(basketItem, item)) {
      foundMatchingItem = true;
      return { ...basketItem, quantity: basketItem.quantity + 1 };
    }
    return basketItem;
  });

  if (!foundMatchingItem) {
    newBasket.push({ ...item, quantity: 1 });
  }

  return newBasket;
};

export const getBasketWithRemovedItem = (
  item: SerializedOrderItemType,
  basket: BasketType
): BasketType => {
  const itemInBasket = basket.find(
    basketItem =>
      basketItem.uuid === item.uuid && compareSubItems(basketItem, item)
  );

  if (!itemInBasket) {
    // This should not be possible - Trying to remove an item which was not in the basket before
    console.error('Trying to remove an item that is not in the basket');
    return basket;
  }

  // Remove item from basket as the quantity would be 0 afterwards
  if (itemInBasket.quantity === 1 && !item.subItems?.length) {
    // If there is only one item with addOnItems left and that gets removed we need to remove the Gedeck
    return basket.filter(basketItem => basketItem.uuid !== item.uuid);
  }

  // Remove item with sub items from basket as the quantity would be 0 afterwards
  if (
    itemInBasket.quantity === 1 &&
    !!item.subItems?.length &&
    !!itemInBasket.subItems?.length
  ) {
    return basket.filter(
      basketItem =>
        basketItem.uuid !== item.uuid || !compareSubItems(basketItem, item)
    );
  }

  // Reducing the quantity on an item
  return basket.map(oldItem => {
    const hasSameIdAndSameSubItems =
      oldItem.uuid === item.uuid && compareSubItems(oldItem, item);
    const hasSubItems = !!(item.subItems && item.subItems.length > 0);

    const isCondition = hasSubItems
      ? hasSameIdAndSameSubItems
      : oldItem.uuid === item.uuid;

    return isCondition
      ? { ...oldItem, quantity: oldItem.quantity - 1 }
      : { ...oldItem };
  });
};
