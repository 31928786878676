/* eslint-disable sonarjs/no-duplicate-string */
import { ThemeType } from 'types';

import { Colors } from './colors';

export const defaultTheme: ThemeType = {
  pageBackgroundColor: Colors.black_100,
  tileBackgroundColor: Colors.black_80,
  textColor: Colors.white_100,
  primaryColor: Colors.skyblue_100,
  buttonTextColor: Colors.black_100,
  applePayTheme: 'white',
  googlePayTheme: 'light',
  logoUrl: {
    dark: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
    light: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
  },
  coverImageUrl:
    'https://luca-app.de/wp-content/uploads/2024/03/Room_3_Balcony-Suite-1367x769-1.jpeg',

  coverTextColor: '',
  supportPhoneNumber: '',
  partnerLogoUrl: '',
  legalLinks: [],
  poweredBy: '',
  information: {
    title: {
      de: '',
      en: '',
      el: '',
    },
    description: {
      de: '',
      en: '',
      el: '',
    },
  },
  floorPlan: {},
  showFloorPlan: false,
  internalName: '',
  meta: {
    title: '',
    description: '',
  },
  tipSettings: {
    defaultTipAmount: 0,
    defaultTipPercentage: 0,
    customTipPercentages: undefined,
  },
  applePayLabel: '',
};

export const defaultLightTheme: ThemeType = {
  pageBackgroundColor: Colors.white_100,
  tileBackgroundColor: Colors.blue_100,
  textColor: Colors.black_100,
  primaryColor: Colors.skyblue_100,
  buttonTextColor: Colors.black_100,
  applePayTheme: 'white',
  googlePayTheme: 'light',
  logoUrl: {
    dark: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
    light: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
  },
  coverImageUrl:
    'https://luca-app.de/wp-content/uploads/2024/03/Room_3_Balcony-Suite-1367x769-1.jpeg',

  coverTextColor: '',
  supportPhoneNumber: '',
  partnerLogoUrl: '',
  legalLinks: [],
  poweredBy: '',
  information: {
    title: {
      de: '',
      en: '',
      el: '',
    },
    description: {
      de: '',
      en: '',
      el: '',
    },
  },
  floorPlan: {},
  showFloorPlan: false,
  internalName: '',
  meta: {
    title: '',
    description: '',
  },
  tipSettings: {
    defaultTipAmount: 0,
    defaultTipPercentage: 0,
    customTipPercentages: undefined,
  },
  applePayLabel: '',
};

export const operaTestingTheme: ThemeType = {
  pageBackgroundColor: '#ffffff',
  tileBackgroundColor: '#edebf0',
  textColor: '#000000',
  primaryColor: '#c71b32',
  buttonTextColor: '#ffffff',
  applePayTheme: 'black',
  googlePayTheme: 'dark',
  logoUrl: {
    dark: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
    light: 'https://luca-app.de/wp-content/uploads/2024/03/image-23.png',
  },
  coverImageUrl:
    'https://luca-app.de/wp-content/uploads/2024/03/Room_3_Balcony-Suite-1367x769-1.jpeg',

  coverTextColor: '',
  supportPhoneNumber: '',
  partnerLogoUrl: '',
  legalLinks: [],
  poweredBy: '',
  information: {
    title: {
      de: '',
      en: '',
      el: '',
    },
    description: {
      de: '',
      en: '',
      el: '',
    },
  },
  floorPlan: {},
  showFloorPlan: false,
  internalName: '',
  meta: {
    title: '',
    description: '',
  },
  tipSettings: {
    defaultTipAmount: 0,
    defaultTipPercentage: 0,
    customTipPercentages: undefined,
  },
  applePayLabel: '',
};
