import { useIntl } from 'react-intl';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useMountEffect } from '@react-hookz/web';

type PropertiesType = { children: JSX.Element } & (
  | { analyticsIdentifier: string }
  | { legacyLocaleId: string }
);
export const AnalyticsViewWrapper = ({
  children,
  ...restProperties
}: PropertiesType) => {
  const intl = useIntl();
  const { trackPageView } = useMatomo();

  useMountEffect(() => {
    if ('analyticsIdentifier' in restProperties) {
      trackPageView({
        documentTitle: intl.formatMessage({
          id: `${restProperties.analyticsIdentifier}.name`,
        }),
        href: intl.formatMessage({
          id: `${restProperties.analyticsIdentifier}.path`,
        }),
      });
      console.info(
        'trackPageView - analyticsIdentifier:',
        restProperties.analyticsIdentifier
      );
    } else {
      trackPageView({
        documentTitle: intl.formatMessage({
          id: restProperties.legacyLocaleId,
        }),
      });
      console.info(
        'trackPageView - legacyLocaleId:',
        restProperties.legacyLocaleId
      );
    }
  });

  return children;
};
