/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
`;
/*
 * This component is used for testing purposes only.
 * It allows to test different ways of redirecting the user to a deeplink URL.
 * The URL can be updated in the input field and then the user can test the different ways of redirecting.
 */
export const DeepLinkPage = () => {
  const [deeplink, setDeeplink] = useState(
    'https://app.luca-app.de/webapp/install'
  );

  const updateDeeplink = (event: { target: { value: string } }) => {
    const newLink = event.target.value;
    setDeeplink(newLink);
    console.info(`Updated deeplink URL: ${newLink}`);
  };

  const setWindowLocation = () => {
    window.location.href = deeplink;
  };

  const useWindowOpen = () => {
    window.open(deeplink, 'deeplink');
  };

  const withDelay = () => {
    setTimeout(() => {
      window.location.href = deeplink;
    }, 1000);
  };

  const serverSideRedirect = () => {
    alert('Not implemented');
  };

  return (
    <div>
      <form>
        <label htmlFor="urlInput">URL:</label>
        <br />
        <Input
          type="text"
          id="urlInput"
          name="urlInput"
          onChange={updateDeeplink}
          value={deeplink}
        />
      </form>
      <p>
        <a href={deeplink}>anchor without target</a>
      </p>
      <p>
        <a href={deeplink} target="_blank" rel="noreferrer">
          anchor with target
        </a>
      </p>
      <p>
        <a href="#" onClick={setWindowLocation}>
          JS window location
        </a>
      </p>
      <p>
        <a href="#" onClick={useWindowOpen}>
          JS window open
        </a>
      </p>
      <p>
        <a href="#" onClick={serverSideRedirect}>
          server-side redirect
        </a>
      </p>
      <p>
        <a href="#" onClick={withDelay}>
          with delay
        </a>
      </p>
    </div>
  );
};
