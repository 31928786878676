export const schengenRaumCountries = [
  // Germany
  'DE',
  // Iceland
  'IS',
  // Belgium
  'BE',
  // Czechia
  'CZ',
  // Denmark
  'DK',
  // Estonia
  'EE',
  // Finland
  'FI',
  // France
  'FR',
  // Greece
  'GR',
  // Italy
  'IT',
  // Croatia
  'HR',
  // Latvia
  'LV',
  // Lichtenstein
  'LI',
  // Lithuania
  'LT',
  // Luxembourg
  'LU',
  // Malta
  'MT',
  // Netherlands
  'NL',
  // Norway
  'NO',
  // Austria
  'AT',
  // Poland
  'PL',
  // Portugal
  'PT',
  // Spain
  'ES',
  // Sweden
  'SE',
  // Switzerland
  'CH',
  // Slovakia
  'SK',
  // Slovenia
  'SI',
  // Hungary
  'HU',
];

export const GERMAN_COUNTRY_CODE = 'DE';

export const DESKTOP_SIDER_WIDTH = 240;
export const DESKTOP_SIDER_MARGIN_WIDTH = 240 + 24;
export const MOBILE_SIDER_WIDTH = 250;
