/* eslint-disable unicorn/prevent-abbreviations */
import { useIntl } from 'react-intl';
import { FlagEnglishIcon, FlagGermanIcon, FlagGreekIcon } from 'assets/icons';

export enum SupportedLanguageEnum {
  de = 'de',
  en = 'en',
  el = 'el',
}
export const defaultLanguage = SupportedLanguageEnum.en;

const isSupportedLanguage = (lang: string): lang is SupportedLanguageEnum =>
  lang === SupportedLanguageEnum.de ||
  lang === SupportedLanguageEnum.en ||
  lang === SupportedLanguageEnum.el;

export const getLanguage = (): SupportedLanguageEnum => {
  const language = navigator.language.split(/[_-]/)[0];
  if (isSupportedLanguage(language)) {
    return language;
  }

  return defaultLanguage;
};

export const useFormatMessage = () => {
  const intl = useIntl();

  return (id: string, values?: Record<string, any>) =>
    intl.formatMessage({ id }, values);
};

export const getLanguageIcon = (locale: string) => {
  switch (locale) {
    case 'de':
      return <FlagGermanIcon style={{ marginRight: '10px' }} />;
    case 'en':
      return <FlagEnglishIcon style={{ marginRight: '10px' }} />;
    case 'el':
      return <FlagGreekIcon style={{ marginRight: '10px' }} />;
    default:
      return null;
  }
};
