export const DarkLoading = () => {
  return (
    <div className="loadingio-spinner-dual-ball-zbui1g3zyl">
      <div className="ldio-68e6sazggxo">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
