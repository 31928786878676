import { useParams, useSearchParams } from 'react-router-dom';

export const PreorderRedirect = () => {
  const [searchParameters] = useSearchParams();
  const { bundleId, checkoutId } = useParams();

  const accessToken = searchParameters.get('accessToken');
  const referer = searchParameters.get('referer');
  if (!!referer && !!bundleId && !!accessToken) {
    window.location.assign(
      `https://${window.location.hostname}/pay/preorder/${bundleId}?accessToken=${accessToken}&referer=${referer}`
    );
    return null;
  }

  if (!!bundleId && !!checkoutId && !!accessToken) {
    window.location.assign(
      `https://${window.location.hostname}/pay/preorder/cancel-preorder/${bundleId}/checkout/${checkoutId}?accessToken=${accessToken}`
    );
    return null;
  }

  if (!!bundleId && !!accessToken) {
    window.location.assign(
      `https://${window.location.hostname}/pay/preorder/cancel-preorder/${bundleId}?accessToken=${accessToken}`
    );
    return null;
  }

  console.error(
    `Nothing to redirect from pathname: ${window.location.pathname} and search parameters: ${window.location.search}`
  );
  return null;
};
