import styled, { css } from 'styled-components';
import { Button } from 'antd';

import {
  primaryStyle,
  secondaryStyle,
  secondaryDarkStyle,
  whiteStyle,
  blackStyle,
  Colors,
} from 'constants/globalStyles';
import { Media } from 'utils';

export const basicButtonStyle = css`
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${Colors.black_100};
  line-height: 22px;
  text-transform: uppercase;
  min-height: 48px;
  height: 48px;
  min-width: 139px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-radius: 24px;
  cursor: pointer;
  transition: 0.1s ease-in-out all;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BasicButton = styled(Button)`
  ${basicButtonStyle}
`;

type ButtonStyleType = {
  mainBorder: string;
  mainBackground: string;
  focusBorder: string;
  focusBackground: string;
  hoverBorder: string;
  hoverBackground: string;
  disabledBorder: string;
  disabledBackground: string;
  color: string;
  disabledColor?: string;
};

export const createButtonStyle = (
  buttonStyle: ButtonStyleType,
  Component: any
) => styled(Component)`
  border: 2px solid ${buttonStyle.mainBorder} !important;
  background: ${buttonStyle.mainBackground} !important;
  color: ${buttonStyle.color} !important;

  &:focus {
    color: ${buttonStyle.color} !important;
    background: ${buttonStyle.focusBackground} !important;
    border-color: ${buttonStyle.focusBorder} !important;
  }

  &:hover {
    color: ${buttonStyle.color} !important;
    background: ${buttonStyle.hoverBackground} !important;
    border-color: ${buttonStyle.hoverBorder} !important;
  }

  &:disabled {
    color: ${buttonStyle.disabledColor} !important;
    background: ${buttonStyle.disabledBackground} !important;
    border-color: ${buttonStyle.disabledBorder} !important;
    cursor: not-allowed !important;
  }

  ${Media.tablet`
    display: flex;
    justify-content: center;
  `}
`;

export const PrimaryButton = createButtonStyle(primaryStyle, BasicButton);
export const SecondaryButton = createButtonStyle(secondaryStyle, BasicButton);
export const SecondaryDarkButton = createButtonStyle(
  secondaryDarkStyle,
  BasicButton
);
export const WhiteButton = createButtonStyle(whiteStyle, BasicButton);
export const BlackButton = createButtonStyle(blackStyle, BasicButton);

export const StyledTextButton = styled(Button)`
  color: ${Colors.skyblue_100};
  font-family: GT-Walsheim-Bold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding: 0 10px;
  align-items: flex-end;
`;
