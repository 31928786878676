import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { BasketType, SerializedOrderItemType } from 'types';

import {
  compareSubItems,
  generateUniqueBasketItemKey,
  getBasketWithAddedItem,
  getItemsInBasket,
  getTotalQuantity,
} from 'utils';

type BasketContainerType = {
  isBasketView: boolean;
  setIsBasketView: (value: boolean) => void;
  basket: BasketType;
  setBasket: (newBasket: BasketType) => void;
  itemsInBasket: boolean;
  itemQuantity: number;
  addItem: (item: SerializedOrderItemType) => void;
  handleRemoveItemFromBasket: (item: SerializedOrderItemType) => void;
  handleRemoveItemFromCategoryList: (item: SerializedOrderItemType) => void;
};

const useBasketContainer = (): BasketContainerType => {
  const [isBasketView, setIsBasketView] = useState<boolean>(false);
  const [basket, setBasket] = useState<BasketType>([]);

  const itemsInBasket = Object.keys(basket).length > 0;
  const itemQuantity = getTotalQuantity(basket);

  const addItem = (item: SerializedOrderItemType) => {
    const newBasket = getBasketWithAddedItem(item, basket);
    setBasket(newBasket);
  };

  const handleRemoveItemFromBasket = (item: SerializedOrderItemType) => {
    let foundMatchingItem = false;

    const itemInBasket = basket.find(
      basketItem =>
        basketItem.uuid === item.uuid && compareSubItems(basketItem, item)
    );
    if (!itemInBasket) {
      console.error('Trying to remove an item that is not in the basket');
      return;
    }

    const newBasket = basket.reduce((accumulator, basketItem) => {
      if (basketItem.uuid === item.uuid && compareSubItems(basketItem, item)) {
        foundMatchingItem = true;
        if (basketItem.quantity > 1) {
          accumulator.push({
            ...basketItem,
            quantity: basketItem.quantity - 1,
          });
        }
        // If quantity is 1, it will not be pushed back, effectively removing it
      } else {
        accumulator.push(basketItem); // Always push non-matching items
      }
      return accumulator;
    }, [] as BasketType);

    if (!foundMatchingItem) {
      console.error(
        'No matching item with identical sub-items found to remove'
      );
    } else {
      setBasket(newBasket);
    }
  };

  const handleRemoveItemFromCategoryList = (item: SerializedOrderItemType) => {
    const foundItemsInBasket = getItemsInBasket(basket, item);
    if (!foundItemsInBasket.length) {
      console.error('Trying to remove an item that is not in the basket');
      return;
    }

    const newBasket = [...basket];

    for (const element of foundItemsInBasket) {
      for (const [index, basketItem] of basket.entries()) {
        if (
          generateUniqueBasketItemKey(element) ===
          generateUniqueBasketItemKey(basketItem)
        ) {
          if (basketItem.quantity === 1) {
            newBasket.splice(index, 1);
            setBasket(newBasket);
            return;
          }

          if (basketItem.quantity > 1) {
            newBasket.splice(index, 1, {
              ...basketItem,
              quantity: basketItem.quantity - 1,
            });
            setBasket(newBasket);
            return;
          }
        }
      }
    }
  };

  return {
    isBasketView,
    setIsBasketView,
    basket,
    setBasket,
    itemsInBasket,
    itemQuantity,
    addItem,
    handleRemoveItemFromBasket,
    handleRemoveItemFromCategoryList,
  };
};

export const BasketContainer = createContainer(useBasketContainer);
