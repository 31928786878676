import { useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DiscoverGroupContainerTypes, SupportedCurrency } from 'types';
import {
  useGetDiscoverLocationGroup,
  useGetDiscoverPaymentSettingsV3,
  useGetGroupSettingsByDiscoverId,
  useGetLocationTableById,
  useGetPublicLocation,
  useGetSpecialStayOffers,
  useNearbyLocations,
} from 'hooks';
import { extractTableFromUrl, getIsUnknownUserFromLocalStorage } from 'utils';
import { defaultTheme } from 'constants/globalStyles';
import { STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY } from 'constants/localStorage';
import { STAY_PATH } from 'constants/routes';

const useStayContainer = (): DiscoverGroupContainerTypes => {
  const { discoverId } = useParams();
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();
  const [isLogoSectionVisible, setIsLogoSectionVisible] = useState(false);

  const fallbackDiscoverId = useMemo(
    () => searchParameters.get('discoverId'),
    [searchParameters]
  );

  const tableFromUrl = extractTableFromUrl();

  const { data: discoverGroup, isLoading: isDiscoverGroupLoading } =
    useGetDiscoverLocationGroup(discoverId || fallbackDiscoverId);
  const {
    data: groupSettings,
    isLoading: isGroupSettingsLoading,
    error: isGroupSettingsError,
  } = useGetGroupSettingsByDiscoverId({ discoverId });
  const { data: publicLocation, isLoading: isPublicLocationLoading } =
    useGetPublicLocation(discoverGroup?.baseLocationId ?? undefined);
  const { data: table, isLoading: isTableLoading } =
    useGetLocationTableById(tableFromUrl);
  const { data: restaurants, isLoading: isRestaurantsLoading } =
    useNearbyLocations({
      discoverId,
      disableStayConciergeLocationsNearBy:
        groupSettings?.theme?.disableStayConciergeLocationsNearBy,
    });

  const {
    data: paymentDiscoverSettings,
    isLoading: isPaymentDiscoverSettingsLoading,
    error: paymentDiscoverSettingsError,
  } = useGetDiscoverPaymentSettingsV3(discoverId);

  const { data: specialOffers, isLoading: isSpecialOffersLoading } =
    useGetSpecialStayOffers({
      locationId: discoverGroup?.baseLocationId ?? undefined,
      disableStaySpecialOffers: groupSettings?.theme?.disableStaySpecialOffers,
    });

  const getIsPMSconnected = () => {
    // if settings are still loading, we assume that we need to authenticate
    if (isGroupSettingsLoading) return true;

    return !!groupSettings?.settings.connectedPMSName;
  };

  const isPMSconnected = getIsPMSconnected();

  const handleNavigate = (path: string) => {
    navigate(`${path}${window.location.hash}`);
  };

  const handleRestrictedNavigate = (path: string) => {
    // Always fetch the latest value of isUnknownUser
    const isUnknownUser = getIsUnknownUserFromLocalStorage();
    if (isUnknownUser) {
      window.localStorage.removeItem(STAY_UNKNOWN_USER_LOCAL_STORAGE_KEY);
      window.location.assign(
        `${STAY_PATH}/${discoverId}${window.location.hash}`
      );
      return;
    }

    handleNavigate(path);
  };

  return {
    disableStayConciergeWishes: false, // default, can be overwritten by theme
    ...discoverGroup,
    ...(groupSettings?.theme || defaultTheme),
    ...groupSettings?.settings,
    orderingHours: publicLocation?.orderingHours,
    orderingClosedUntil: publicLocation?.orderingClosedUntil,
    locationName: discoverGroup?.name,
    paymentLimits: {
      minInvoiceAmount: paymentDiscoverSettings?.minInvoiceAmount || null,
      maxInvoiceAmount: paymentDiscoverSettings?.maxInvoiceAmount || null,
      maxTipAmount: paymentDiscoverSettings?.maxTipAmount || null,
    },
    currency: paymentDiscoverSettings?.currency || SupportedCurrency.EUR,
    restaurants,
    paymentDiscoverSettingsError,
    isPaymentDiscoverSettingsLoading,
    isGroupSettingsLoading,
    isGroupSettingsError,
    isDiscoverGroupLoading,
    isPublicLocationLoading,
    isRestaurantsLoading,
    isTableLoading,
    theme: groupSettings?.theme,
    isPMSconnected,
    specialOffers,
    isSpecialOffersLoading,
    tableFromUrl,
    table,
    handleNavigate,
    handleRestrictedNavigate,
    discoverId,
    setIsLogoSectionVisible,
    isLogoSectionVisible,
    askForMembershipEnrolement:
      groupSettings?.theme?.askForMembershipEnrolement ?? false,
    askForMembershipNumber:
      groupSettings?.theme?.askForMembershipNumber ?? false,
    membershipEnrolementText: groupSettings?.theme?.membershipEnrolementText,
    membershipNumberText: groupSettings?.theme?.membershipNumberText,
  };
};

export const StayContainer = createContainer(useStayContainer);
