export const LoadingSpinner = () => {
  return (
    <div className="loadingio-spinner-ellipsis-afx9z4ishqt">
      <div className="ldio-8dz1mlkc5ua">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
