import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  hashedUrlAdditionalInformationType,
  LocationScannerContainerType,
} from 'types';
import {
  useGetCampaignsForLocation,
  useGetLocation,
  useGetLocationGroup,
  useGetLocationGroupPaymentSettingsV3,
  useGetLocationGroupSettingsByLocationId,
  useGetLocationMenuV3Flat,
  useGetLocationTableById,
  useGetLocationURLs,
  useGetPublicLocation,
  useGetScanner,
  useNotifications,
} from 'hooks';
import {
  extractAdditionalInfoFromUrl,
  extractIsHotelOrderingFromUrl,
  extractIsSelfCheckoutFromUrl,
  extractTableFromUrl,
  getConsumerFromLocalStorage,
  getMenuRoute,
  getPaymentRoute,
  isValidUUID,
  useLocalStorage,
} from 'utils';
import { BASE_PATH, HOTEL_PATH, SELF_CHECKOUT_PATH } from 'constants/routes';
import { HOTEL_ORDERING_ENTRY_URL } from 'constants/sessionStorage';

const useLocationScannerContainer = (): LocationScannerContainerType => {
  const { scannerId } = useParams();
  const consumer = getConsumerFromLocalStorage();

  const [, setEntryUrl] = useLocalStorage<string | null | undefined>(
    HOTEL_ORDERING_ENTRY_URL,
    null
  );
  const tableInfo = extractTableFromUrl();
  const additionalHotelUrlData = extractIsHotelOrderingFromUrl();
  const additionalSelfCheckoutUrlData = extractIsSelfCheckoutFromUrl();
  const [additionalInfo, setAdditionalInfo] =
    useState<hashedUrlAdditionalInformationType>();
  const [isLoadingAdditionalInfo, setIsLoadingAdditionalInfo] = useState(true);

  const navigate = useNavigate();
  const { errorMessage } = useNotifications();

  const isValidUuid = isValidUUID(scannerId);

  if (!scannerId) {
    throw new Error('Called page without valid scannerId');
  }

  useEffect(() => {
    setAdditionalInfo(extractAdditionalInfoFromUrl());
  }, []);

  // Once we finish fetching additionalInfo, we can manually set the loading state
  useEffect(() => {
    if (typeof additionalInfo !== 'undefined') {
      setIsLoadingAdditionalInfo(false);
    }
  }, [additionalInfo]);

  const {
    data: scanner,
    error: scannerError,
    isLoading: isScannerLoading,
  } = useGetScanner(scannerId);

  const {
    data: table,
    error: tableError,
    isLoading: isTableLoading,
  } = useGetLocationTableById(tableInfo);

  const {
    data: location,
    error: locationError,
    isLoading: isLocationLoading,
  } = useGetLocation(scanner?.locationId);

  const {
    data: groupSettings,
    error: groupSettingsError,
    isLoading: isGroupSettingsLoading,
  } = useGetLocationGroupPaymentSettingsV3(location?.groupId);

  const { data: locationMenu, isLoading: isMenuLoading } =
    useGetLocationMenuV3Flat(location?.locationId);

  const { data: group } = useGetLocationGroup(location?.groupId);

  const { data: links } = useGetLocationURLs(scanner?.locationId);

  const { data: campaigns } = useGetCampaignsForLocation({
    locationId: scanner?.locationId,
    consumerData: consumer,
  });

  const { data: publicLocation, isLoading: isPublicLocationLoading } =
    useGetPublicLocation(scanner?.locationId);

  const {
    data: groupSettingsForTheme,
    isLoading: isGroupSettingsForThemeLoading,
  } = useGetLocationGroupSettingsByLocationId(scanner?.locationId);

  const paymentRoute = getPaymentRoute({
    locationId: scanner?.locationId,
    table,
    tableInfo,
    additionalInfo,
  });

  const menuRoute = getMenuRoute({
    locationId: scanner?.locationId,
    tableInfo,
  });

  const isHotelLocation = location?.locationType === 'hotel' || false;

  useEffect(() => {
    if (
      !isScannerLoading &&
      additionalHotelUrlData?.isHotelOrdering &&
      scanner?.locationId &&
      window.location.hash
    ) {
      const replaceUrl = `${HOTEL_PATH}/${scanner?.locationId}${window.location.hash}`;
      setEntryUrl(window.location.href);
      window.location.replace(replaceUrl);
    }
    if (
      !isScannerLoading &&
      additionalSelfCheckoutUrlData?.isSelfCheckout &&
      scanner?.locationId
    ) {
      const replaceUrl = `${SELF_CHECKOUT_PATH}/${scanner?.discoverId}`;
      setEntryUrl(window.location.href);
      navigate(replaceUrl, {
        state: {
          locationId: scanner?.locationId,
        },
      });
    }
    if (!isScannerLoading && !isLocationLoading && isHotelLocation) {
      setEntryUrl(window.location.href);
    }
  }, [
    additionalHotelUrlData,
    additionalSelfCheckoutUrlData,
    scanner,
    setEntryUrl,
    isHotelLocation,
    isLocationLoading,
    isScannerLoading,
    navigate,
  ]);

  if (
    !isValidUuid ||
    (!scanner && !isScannerLoading) ||
    scannerError ||
    locationError
  ) {
    errorMessage('notification.error.checkin');
    navigate(BASE_PATH, { replace: true });
  }

  return {
    theme: groupSettingsForTheme?.theme,
    isGroupSettingsForThemeLoading,
    groupSettings: groupSettings ?? null,
    groupSettingsError,
    isGroupSettingsLoading,
    location: location ?? null,
    locationError,
    isLocationLoading,
    group: group ?? null,
    links: links ?? null,
    campaigns: campaigns ?? null,
    publicLocation: publicLocation ?? null,
    isPublicLocationLoading,
    locationMenu,
    isMenuLoading,
    scanner: scanner ?? null,
    table,
    tableInfo,
    paymentRoute,
    additionalInfo,
    menuRoute,
    tableError,
    isScannerLoading,
    isTableLoading,
    isLoadingAdditionalInfo,
    isHotelLocation,
  };
};

export const LocationScannerContainer = createContainer(
  useLocationScannerContainer
);
