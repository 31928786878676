import validator from 'validator';
import parsePhoneNumber from 'libphonenumber-js/max';

export const isValidUUID = (uuid?: string) => {
  return typeof uuid === 'string' && validator.isUUID(uuid, 'all');
};

export const getIsEmail = (inputString: string | undefined) =>
  inputString?.includes('@');

export const isValidPhoneNumber = (value: string | undefined) => {
  const parsedNumber = parsePhoneNumber(value || '', {
    extract: false,
    defaultCountry: 'DE',
  });

  return !!parsedNumber?.isValid();
};
