import { useState } from 'react';
import { createContainer } from 'unstated-next';
import dayjs from 'dayjs';
import moment from 'moment';
import { getLanguage, SupportedLanguageEnum } from 'utils';

const useLanguageContainer = (
  initialLanguage: SupportedLanguageEnum = getLanguage()
) => {
  const [currentLocale, setCurrentLocale] = useState(initialLanguage);

  const setCurrentLocaleAdvanced = (newLocale: SupportedLanguageEnum) => {
    setCurrentLocale(newLocale);
    dayjs.locale(newLocale);
    dayjs.updateLocale(newLocale, {});

    moment.locale(newLocale);
  };

  return {
    currentLocale,
    setCurrentLocale: setCurrentLocaleAdvanced,
  };
};

export const LanguageContainer = createContainer(useLanguageContainer);
