import { createContainer } from 'unstated-next';
import { useParams } from 'react-router-dom';
import { ApiKeyContainerTypes } from 'types';
import { useGetOrderSubIdForLocation } from 'hooks';
import {
  getLinkApiKeyFromLocalStorage,
  getTerminalIdFromLocalStorage,
} from 'utils';

const useApiKeyContainer = (): ApiKeyContainerTypes => {
  const { locationId } = useParams();

  const apiKey = getLinkApiKeyFromLocalStorage();
  const terminalId = getTerminalIdFromLocalStorage();

  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetOrderSubIdForLocation({
      locationId,
      apiKey,
    });

  return {
    apiKey,
    terminalId,
    subscriptionId: subscription?.subscriptionId,
    isSubscriptionLoading,
  };
};

export const ApiKeyContainer = createContainer(useApiKeyContainer);
