import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { v4 } from 'uuid';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { DarkLoading, LoadingWrapper } from 'components/general';
import { CONSUMER_LOCAL_STORAGE_KEY } from 'constants/localStorage';
import { ApiError, createConsumer, getAuthenticationCode } from 'network';
import { DepricatedConsumerDataType, ConsumerDataType } from 'types';
import { checkForOldConsumerData, useLocalStorage } from 'utils';

type ConsumerContextType = {
  consumerData: ConsumerDataType | null;
  setConsumerData: (consumerData: ConsumerDataType | null) => void;
};

export const ConsumerContext = createContext<ConsumerContextType>({
  consumerData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConsumerData: () => {},
});

export const useConsumerContext = () => {
  return useContext(ConsumerContext);
};

export const ConsumerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [consumerData, setConsumerData]: readonly [
    ConsumerDataType | null,
    (consumerData: ConsumerDataType | null) => void
  ] = useLocalStorage(CONSUMER_LOCAL_STORAGE_KEY, null);

  const { trackEvent } = useMatomo();

  useEffect(() => {
    if (!consumerData) {
      const username = v4();
      const password = v4();
      const name = v4();

      const createConsumerData = async () => {
        try {
          const createdConsumerResponse = await createConsumer(
            username,
            password,
            name
          );

          setConsumerData({
            username,
            password,
            revocationCode: createdConsumerResponse.revocationCode,
          });
        } catch {
          throw new Error('Failed to create new consumer');
        }
      };

      createConsumerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (consumerData) {
      getAuthenticationCode(consumerData.username, consumerData.password).catch(
        (error: ApiError) => {
          if (error.status !== 401) {
            return;
          }
          trackEvent({
            category: 'PayWebapp',
            action: `Mount App: Failed to get authentication code for consumer with username: ${consumerData?.username}`,
          });
          console.error(`${error} - getting new authentication code...`);
          setConsumerData(null);
        }
      );
      if (checkForOldConsumerData(consumerData as DepricatedConsumerDataType)) {
        setConsumerData({
          username: consumerData.username,
          password: consumerData.password,
          revocationCode: consumerData.revocationCode,
        });
      }
    }
  }, [consumerData, setConsumerData, trackEvent]);

  const memorizedValue = useMemo(
    () => ({
      consumerData,
      setConsumerData,
    }),
    [consumerData, setConsumerData]
  );

  if (!consumerData) {
    return (
      <LoadingWrapper>
        <DarkLoading />
      </LoadingWrapper>
    );
  }

  return (
    <ConsumerContext.Provider value={memorizedValue as ConsumerContextType}>
      {children}
    </ConsumerContext.Provider>
  );
};
