export const isIOSWebView = () => {
  const { userAgent } = navigator;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  let isWebView = false;

  if (isIOS) {
    // Check for WebView in iOS 8 and newer
    isWebView =
      userAgent.includes('AppleWebKit') &&
      userAgent.includes('Mobile') &&
      !/safari|chrome|crios|opios|fxios|mercury/i.test(userAgent);

    // Check for WebView in iOS 7 and older
    if (!isWebView) {
      isWebView = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(
        userAgent
      );
    }
  }

  return isWebView;
};

export const isAndroidWebView = () => 'Android' in window;
export const isMobileWebView = () => isAndroidWebView() || isIOSWebView();
