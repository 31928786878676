import { useIntl } from 'react-intl';
import { Rule } from 'antd/es/form';

import validator from 'validator';
import * as yup from 'yup';
import { Schema } from 'yup';

export const yupSync = (schema: Schema) => {
  return {
    async validator({ field }: { field: string }, value: string) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  } as unknown as Rule;
};

export const EMAIL_REQUIRED_ERROR = 'generic.email.required.error';
export const GENERIC_REQUIRED_ERROR = 'generic.required.error';
export const MIN_LENGTH_ERROR = 'generic.idMinlength.error';
export const MAX_LENGTH_ERROR = 'generic.idMaxlength.error';
export const INVALID_FORMAT_ERROR = 'generic.idFormat.error';
export const GENERIC_ZIP_LENGTH_ERROR = 'generic.zipLength.error';
export const REQUIRED_ERROR = 'generic.required.error';
export const INVALID_PHONE_ERROR = 'generic.phoneNumber.invalid.error';
export const INVALID_EMAIL_ERROR = 'generic.email.invalid.error';

export const useYup = () => {
  const intl = useIntl();

  return {
    email: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: EMAIL_REQUIRED_ERROR }))
      .test(
        'test-email',
        intl.formatMessage({ id: 'Form.Validation.email' }),
        (value: string | undefined) => {
          return !!validator.isEmail(value || '', {
            allow_display_name: false,
            require_display_name: false,
            allow_utf8_local_part: true,
            require_tld: true,
            allow_ip_domain: false,
            blacklisted_chars: "=',\\\\",
          });
        }
      ),
    phone: yup.string().required(
      intl.formatMessage({
        id: GENERIC_REQUIRED_ERROR,
      })
    ),
    zipCode: yup
      .string()
      .matches(/^\d{5}$/, intl.formatMessage({ id: GENERIC_ZIP_LENGTH_ERROR })),
    generalName: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
      .max(255),
    requiredString: yup
      .string()
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR }))
      .max(255),
    requiredDate: yup
      .date()
      .typeError(intl.formatMessage({ id: 'generic.date.typeError' }))
      .required(intl.formatMessage({ id: GENERIC_REQUIRED_ERROR })),
  };
};
