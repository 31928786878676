import { useState, useEffect } from 'react';

let observers: {
  update: React.Dispatch<React.SetStateAction<any>>;
  key: string;
}[] = [];

// updates all observers
const setStoredValue = <T>(value: T, key: string) => {
  observers.forEach(item => {
    if (item.key === key) item.update(value);
  });
};

// Reference: https://usehooks.com/useLocalStorage/
export const useLocalStorage = <T>(
  key: string,
  initialValue: string | null
) => {
  const [storedValueState, setStoredValueState] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  useEffect(() => {
    observers.push({ update: setStoredValueState, key });
    return () => {
      observers = observers.filter(item => item.update !== setStoredValueState);
    };
  }, [key]);
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((value_: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValueState) : value;

      setStoredValue(valueToStore, key);

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValueState, setValue] as const;
};
