/* eslint-disable max-lines */
import { useLocation } from 'react-router-dom';
import { UseQueryOptions, useQuery } from 'react-query';
import dayjs from 'dayjs';
import { getVersion } from 'network/staticApi';
import {
  getScanner,
  getLocationTableById,
  getLocation,
  getLocationGroup,
  getDiscoverLocationGroup,
  getLocationURLs,
  getClientConfig,
  getReservationExperience,
  getCorrespondingTableByName,
  fetchNearbyLocations,
  fetchFeaturedLocations,
} from 'network/webApi';
import {
  getLocationGroupConfig,
  getOpenLocationTablePaymentRequests,
  getLocationGroupSettingsByLocationId,
  getClubInvitation,
  getClubMemberReferral,
  getPreorderShows,
  getCampaignsForLocation,
  getPossibleGuestCounts,
  getCheckout,
  getMe,
  getOrderById,
  getVoucher,
  getPreorderLocations,
  getMeProfile,
  getBundle,
  getPreorderShowOccurrences,
  getConsumerReservation,
  getMeWithAccessToken,
  getOtherOpenCheckoutRequests,
  getConsumerPaymentMethodsV2,
  getConsumerPaymentMethodsV3,
  getLocationMenuV3Flat,
  getOpenTablesForSlots,
  getReservationFolios,
  getPaymentSettingsV3,
  getDiscoverPaymentSettingsV3,
  getPublicLocation,
  getOrderSubIdForLocation,
  getActiveStayReservationsForConsumer,
  getBooking,
  getReservationOrdersForConsumer,
  getImageUrl,
  getGroupSettingsByDiscoverId,
  getOrdersForConsumer,
  getConsumerHotelGuestWishOptions,
  getReceiptPayment,
  getLocationSettings,
  getConsumerHotelGuestWishesForReservation,
  getSpecialStayOffers,
  getPossibleRoomUpgrade,
  getPublicOrderById,
  getMinibarOffers,
  getReservationFolioById,
} from 'network';
import {
  ConsumerDataType,
  GetCheckoutResponse,
  TableData,
  DenormalizedMenuType,
  LocationType,
  StayBookingType,
} from 'types';

export enum QueryKeys {
  VERSION = 'VERSION',
  SCANNER = 'SCANNER',
  LOCATION_URLS = 'LOCATION_URLS',
  CAMPAIGNS = 'CAMPAIGNS',
  LOCATION_GROUP_SETTINGS = 'LOCATION_GROUP_SETTINGS',
  LOCATION_FLAT_MENU = 'LOCATION_FLAT_MENU',
  SHARES_LOCATION_GROUP = 'SHARES_LOCATION_GROUP',
  LOCATION_GROUP = 'LOCATION_GROUP',
  DISCOVER_LOCATION_GROUP = 'DISCOVER_LOCATION_GROUP',
  CLUB_INVITATION = 'CLUB_INVITATION',
  CONSUMER_ME = 'CONSUMER_ME',
  CLUB_MEMBER_REFERRAL = 'CLUB_MEMBER_REFERRAL',
  PREORDER_SHOWS = 'PREORDER_SHOWS',
  POSSIBLE_GUEST_COUNTS = 'POSSIBLE_GUEST_COUNTS',
  ME = 'ME',
  ME_PROFILE = 'ME_PROFILE',
  LOCATION = 'LOCATION',
  LOCATION_TABLE_PAYMENT_REQUEST = 'LOCATION_TABLE_PAYMENT_REQUEST',
  LOCATION_GROUP_CONFIG = 'LOCATION_GROUP_CONFIG',
  CAMPAIGNS_LOCATION = 'CAMPAIGNS_LOCATION',
  GET_CHECKOUT = 'GET_CHECKOUT',
  LOCATION_OPEN_CHECKOUT_REQUEST = 'LOCATION_OPEN_CHECKOUT_REQUEST',
  PAYMENT_METHODS_V2 = 'PAYMENT_METHODS_V2',
  PAYMENT_METHODS_V3 = 'PAYMENT_METHODS_V3',
  APPLE_PAY_SESSION = 'APPLE_PAY_SESSION',
  PAYMENT_LIMITS = 'PAYMENT_LIMITS',
  RESERVATION = 'RESERVATION',
  RESERVATIONS = 'RESERVATIONS',
  VOUCHER = 'VOUCHER',
  ORDER = 'ORDER',
  TABLE = 'TABLE',
  CHECKOUT = 'CHECKOUT',
  PREORDER_LOCATIONS = 'PREORDER_LOCATIONS',
  BUNDLE = 'BUNDLE',
  PREORDER_SHOW_TIMES = 'PREORDER_SHOW_TIMES',
  LOCATION_OPEN_CHECKOUT_REQUEST_BY_OTHERS = 'LOCATION_OPEN_CHECKOUT_REQUEST_BY_OTHERS',
  CLIENT_CONFIG = 'CLIENT_CONFIG',
  RESERVATION_EXPERIENCE = 'RESERVATION_EXPERIENCE',
  OPEN_TABLES_FOR_SLOTS = 'OPEN_TABLES_FOR_SLOTS',
  TABLE_FOR_LOCATION = 'TABLE_FOR_LOCATION',
  CHECKOUT_FOLIO = 'CHECKOUT_FOLIO',
  PAYMENT_SETTINGS = 'PAYMENT_SETTINGS',
  PUBLIC_LOCATION = 'PUBLIC_LOCATION',
  ORDER_SUBSCRIPTION_ID = 'ORDER_SUBSCRIPTION_ID',
  STAY_RESERVATIONS = 'STAY_RESERVATIONS',
  STAY_BOOKING = 'STAY_BOOKING',
  STAY_RESERVATION_ORDERS = 'STAY_RESERVATION_ORDERS',
  UPLOADED_IMAGE = 'UPLOADED_IMAGE',
  GROUP_SETTINGS = 'GROUP_SETTINGS',
  CONSUMER_ORDERS = 'CONSUMER_ORDERS',
  NEARBY_LOCATIONS = 'NEARBY_LOCATIONS',
  FEATURED_LOCATIONS = 'FEATURED_LOCATIONS',
  CONSUMER_HOTEL_GUEST_WISH_OPTIONS = 'CONSUMER_HOTEL_GUEST_WISH_OPTIONS',
  RECEIPT_PAYMENT = 'RECEIPT_PAYMENT',
  LOCATION_SETTINGS = 'LOCATION_SETTINGS',
  CONSUMER_HOTEL_GUEST_WISHES = 'CONSUMER_HOTEL_GUEST_WISHES',
  SPECIAL_STAY_OFFERS = 'SPECIAL_STAY_OFFERS',
  POSSIBLE_ROOM_UPGRADE = 'POSSIBLE_ROOM_UPGRADE',
  PUBLIC_ORDER = 'PUBLIC_ORDER',
  MINIBAR_OFFERS = 'MINIBAR_OFFERS',
  CHECKOUT_FOLIO_ID = 'CHECKOUT_FOLIO_ID',
}

const noPressure = {
  // staleTime: 300_000,
  refetchOnWindowFocus: false,
  retry: false,
  refetchOnReconnect: false,
};

export const useGetVersion = () =>
  useQuery([QueryKeys.VERSION], () => getVersion(), {
    refetchOnWindowFocus: false,
    staleTime: Number.POSITIVE_INFINITY,
    retry: false,
  });

export const useGetScanner = (scannerId?: string) =>
  useQuery([QueryKeys.SCANNER, scannerId], () => getScanner(scannerId), {
    enabled: !!scannerId,
    ...noPressure,
  });

export const useGetLocationTableById = (tableInfo?: TableData | null) =>
  useQuery(
    [QueryKeys.TABLE, tableInfo?.tableId],
    () => getLocationTableById(tableInfo?.tableId),
    {
      enabled: !!tableInfo && !!tableInfo?.tableId,
      ...noPressure,
    }
  );

export const useGetLocationURLs = (locationId?: string) =>
  useQuery(
    [QueryKeys.LOCATION_URLS, locationId],
    () => getLocationURLs(locationId),
    {
      enabled: !!locationId,
      ...noPressure,
    }
  );

export const useGetCampaignsForLocation = ({
  locationId,
  consumerData,
}: {
  locationId: string | undefined;
  consumerData: ConsumerDataType | null;
}) =>
  useQuery(
    QueryKeys.CAMPAIGNS_LOCATION,
    () => getCampaignsForLocation(locationId, consumerData),
    { enabled: !!consumerData && !!locationId, ...noPressure }
  );

export const useGetLocationGroup = (locationGroupId?: string) =>
  useQuery(
    [QueryKeys.LOCATION_GROUP, locationGroupId],
    () => getLocationGroup(locationGroupId as string),
    { enabled: !!locationGroupId, ...noPressure }
  );

export const useGetDiscoverLocationGroup = (
  discoverId: string | null | undefined
) =>
  useQuery(
    [QueryKeys.DISCOVER_LOCATION_GROUP, discoverId],
    () => getDiscoverLocationGroup(discoverId),
    {
      enabled: !!discoverId,
      ...noPressure,
    }
  );

export const useGetOpenPaymentRequestsForLocationTable = ({
  locationId,
  tableId,
  enabled,
}: {
  locationId?: string;
  tableId?: string | null;
  enabled?: boolean;
}) =>
  useQuery(
    [QueryKeys.LOCATION_TABLE_PAYMENT_REQUEST, locationId],
    () => getOpenLocationTablePaymentRequests(locationId, tableId),
    {
      refetchInterval: 5000,
      enabled: enabled && !!locationId,
    }
  );

export const useGetLocationGroupSettingsByLocationId = (locationId?: string) =>
  useQuery(
    [QueryKeys.LOCATION_GROUP_SETTINGS, locationId],
    () => getLocationGroupSettingsByLocationId(locationId),
    {
      enabled: !!locationId,
      ...noPressure,
    }
  );

export const useGetLocationGroupPaymentSettingsV3 = (
  locationGroupId?: string
) =>
  useQuery(
    [QueryKeys.PAYMENT_SETTINGS, locationGroupId],
    () => getPaymentSettingsV3(locationGroupId as string),
    {
      enabled: !!locationGroupId,
      ...noPressure,
    }
  );

export const useGetDiscoverPaymentSettingsV3 = (discoverId?: string | null) =>
  useQuery(
    [QueryKeys.PAYMENT_SETTINGS, discoverId],
    () => getDiscoverPaymentSettingsV3(discoverId as string),
    {
      enabled: !!discoverId,
      ...noPressure,
    }
  );

export const useGetPublicLocation = (locationId?: string) =>
  useQuery(
    [QueryKeys.PUBLIC_LOCATION, locationId],
    () => getPublicLocation(locationId as string),
    {
      enabled: !!locationId,
      ...noPressure,
    }
  );

export const useGetLocationMenuV3Flat = (
  locationId?: string | null,
  date: string = dayjs().startOf('minute').toISOString(),
  options: Omit<
    UseQueryOptions<
      DenormalizedMenuType,
      unknown,
      DenormalizedMenuType,
      [QueryKeys.LOCATION_FLAT_MENU, string | undefined | null]
    >,
    'queryKey' | 'queryFn'
  > = {}
) =>
  useQuery(
    [QueryKeys.LOCATION_FLAT_MENU, locationId],
    () => getLocationMenuV3Flat(locationId as string, date),
    {
      enabled: !!locationId,
      ...noPressure,
      cacheTime: 1000 * 60 * 60 * 24,
      ...options,
    }
  );

export const useGetLocationSettings = (locationId?: string | null) => {
  return useQuery(
    [QueryKeys.LOCATION_SETTINGS, locationId],
    () => getLocationSettings(locationId as string),
    {
      enabled: !!locationId,
      ...noPressure,
      cacheTime: 1000 * 60 * 60 * 24,
    }
  );
};

export const useGetClubInvitation = (inviteId: string | undefined) =>
  useQuery(QueryKeys.CLUB_INVITATION, () => getClubInvitation(inviteId), {
    enabled: !!inviteId,
    ...noPressure,
  });

export const useGetMeWithAccessToken = () => {
  const { hash } = useLocation();
  const accessToken = hash.slice(1);

  return useQuery(
    [QueryKeys.CONSUMER_ME],
    () => getMeWithAccessToken(accessToken),
    {
      enabled: !!accessToken,
      ...noPressure,
    }
  );
};

export const useGetMe = ({
  consumerData,
}: {
  consumerData: ConsumerDataType | null;
}) =>
  useQuery(QueryKeys.ME, () => getMe({ consumerData }), {
    enabled: !!consumerData,
  });

export const useGetClubMemberReferral = (
  clubReferralId: string | undefined
) => {
  return useQuery(
    QueryKeys.CLUB_MEMBER_REFERRAL,
    () => getClubMemberReferral(clubReferralId),
    {
      enabled: !!clubReferralId,
      ...noPressure,
    }
  );
};

export const useGetPreorderShows = (discoverId?: string) => {
  return useQuery(
    QueryKeys.PREORDER_SHOWS,
    () => getPreorderShows(discoverId),
    {
      enabled: !!discoverId,
      retry: false,
      cacheTime: 0,
    }
  );
};

export const useGetPreorderShowOccurrences = (
  showOccurrenceId: string | undefined
) => {
  return useQuery(
    QueryKeys.PREORDER_SHOW_TIMES,
    () => getPreorderShowOccurrences(showOccurrenceId),
    {
      enabled: !!showOccurrenceId,
      retry: false,
      cacheTime: 0,
    }
  );
};

export const useGetBundle = ({
  consumerAccessToken,
  consumerData,
  enabled,
}: {
  consumerAccessToken?: string | null;
  consumerData?: ConsumerDataType | null;
  enabled?: boolean;
}) => {
  return useQuery(
    [QueryKeys.BUNDLE, consumerAccessToken],
    () => getBundle({ consumerAccessToken, consumerData }),
    {
      enabled: (!!consumerAccessToken || !!consumerData) && enabled,
      retry: false,
      cacheTime: 0,
    }
  );
};

export const useGetPreorderLocations = (discoverId?: string) => {
  return useQuery(
    [QueryKeys.PREORDER_LOCATIONS, discoverId],
    () => getPreorderLocations(discoverId),
    {
      enabled: !!discoverId,
      retry: false,
      cacheTime: 0,
    }
  );
};

export const useGetPossibleGuestCounts = (
  locationId: string | null | undefined,
  showOccurrenceSlotId: string | undefined
) => {
  return useQuery(
    [QueryKeys.POSSIBLE_GUEST_COUNTS, showOccurrenceSlotId],
    () => getPossibleGuestCounts(locationId, showOccurrenceSlotId),
    {
      enabled: !!showOccurrenceSlotId,
      retry: false,
      cacheTime: 0,
    }
  );
};

export const useGetLocation = (locationId?: string) => {
  return useQuery(
    [QueryKeys.LOCATION, locationId],
    () => getLocation(locationId as string),
    {
      enabled: !!locationId,
      ...noPressure,
    }
  );
};

export const useGetVoucher = (voucherCode?: string | null) =>
  useQuery(QueryKeys.VOUCHER, () => getVoucher(voucherCode), {
    enabled: !!voucherCode,
    retry: false,
  });

// TODO: fetch once and hold in state, therefore we can always calculate them and remove options here
export const useGetLocationGroupConfig = (
  consumerData: ConsumerDataType | null,
  discoverId?: string
) =>
  useQuery(
    QueryKeys.LOCATION_GROUP_CONFIG,
    () => getLocationGroupConfig(discoverId as string, consumerData),
    { enabled: !!discoverId, ...noPressure }
  );

export const useGetMeProfile = ({
  consumerData,
}: {
  consumerData: ConsumerDataType | null;
}) =>
  useQuery(QueryKeys.ME_PROFILE, () => getMeProfile({ consumerData }), {
    enabled: !!consumerData,
    ...noPressure,
  });

export const useGetCheckout = (
  checkoutId: string | null | undefined,
  options?: Omit<
    UseQueryOptions<
      GetCheckoutResponse,
      unknown,
      GetCheckoutResponse,
      QueryKeys.GET_CHECKOUT
    >,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery(QueryKeys.GET_CHECKOUT, () => getCheckout(checkoutId as string), {
    refetchOnWindowFocus: false,
    ...options,
    enabled:
      typeof options?.enabled === 'boolean'
        ? options.enabled && !!checkoutId
        : !!checkoutId,
  });

export const useGetOrderById = ({
  orderId,
  consumerData,
}: {
  orderId?: string | null;
  consumerData: ConsumerDataType | null;
}) =>
  useQuery(
    [QueryKeys.ORDER, orderId],
    () => getOrderById({ orderId, consumerData }),
    {
      enabled: !!orderId,
    }
  );

export const useGetTableById = (
  tableId?: string | null,
  options?: { enabled: boolean }
) =>
  useQuery([QueryKeys.TABLE, tableId], () => getLocationTableById(tableId), {
    enabled: !!tableId,
    ...options,
  });

export const useGetConsumerReservation = (
  consumerAccessToken: string,
  enabled: boolean
) =>
  useQuery(
    [QueryKeys.RESERVATIONS, consumerAccessToken],
    () => getConsumerReservation(consumerAccessToken),
    {
      enabled,
      retry: false,
    }
  );

export const useGetOpenCheckoutRequestsByOthers = ({
  consumerData,
  locationId,
  paymentRequestId,
}: {
  consumerData: ConsumerDataType | null;
  locationId?: string;
  paymentRequestId?: string;
}) =>
  useQuery(QueryKeys.LOCATION_OPEN_CHECKOUT_REQUEST_BY_OTHERS, () =>
    getOtherOpenCheckoutRequests({
      consumerData,
      locationId,
      paymentRequestId,
    })
  );

export const useFeatureFlags = () =>
  useQuery(QueryKeys.CLIENT_CONFIG, () => getClientConfig(), {
    staleTime: Number.POSITIVE_INFINITY,
  });

export const useGetReservationExperience = (
  consumerData?: ConsumerDataType | null,
  experienceId?: string
) =>
  useQuery(
    [QueryKeys.RESERVATION_EXPERIENCE, experienceId],
    () => getReservationExperience({ consumerData, experienceId }),
    {
      enabled: !!experienceId && !!consumerData,
      retry: false,
    }
  );
export const useGetConsumerPaymentMethodsV2 = (
  consumerData: ConsumerDataType | null
) =>
  useQuery(
    QueryKeys.PAYMENT_METHODS_V2,
    () => getConsumerPaymentMethodsV2(consumerData as ConsumerDataType),
    {
      retry: false,
      enabled: !!consumerData,
    }
  );
export const useGetConsumerPaymentMethodsV3 = (
  consumerData: ConsumerDataType | null
) =>
  useQuery(
    QueryKeys.PAYMENT_METHODS_V3,
    () => getConsumerPaymentMethodsV3(consumerData as ConsumerDataType),
    {
      retry: false,
      enabled: !!consumerData,
    }
  );

export const useGetOpenTablesForSlots = ({
  consumerAccessToken,
}: {
  consumerAccessToken?: string | null;
}) =>
  useQuery(
    QueryKeys.OPEN_TABLES_FOR_SLOTS,
    () => getOpenTablesForSlots({ consumerAccessToken }),
    {
      enabled: !!consumerAccessToken,
    }
  );

export const useGetCorrespondingTableByName = ({
  tableNameOrNumber,
  locationId,
}: {
  tableNameOrNumber?: string;
  locationId: string;
}) =>
  useQuery(
    [QueryKeys.TABLE_FOR_LOCATION, locationId, tableNameOrNumber],
    () => getCorrespondingTableByName({ tableNameOrNumber, locationId }),
    {
      enabled: !!locationId && !!tableNameOrNumber,
      ...noPressure,
    }
  );

export const useGetReservationFolios = ({
  pmsReference,
  discoverId,
  consumerData,
  consumerAccessToken,
  isPMSconnected,
  booking,
}: {
  pmsReference?: string | null;
  discoverId?: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  isPMSconnected: boolean;
  booking: StayBookingType | null | undefined;
}) =>
  useQuery(
    [QueryKeys.CHECKOUT_FOLIO, pmsReference],
    () =>
      getReservationFolios({
        pmsReference,
        discoverId,
        consumerData,
        consumerAccessToken,
      }),
    {
      enabled: !!pmsReference && !!discoverId && isPMSconnected && !!booking,
      ...noPressure,
      staleTime: 0,
    }
  );

export const useGetReservationFolioById = ({
  pmsReference,
  discoverId,
  consumerData,
  consumerAccessToken,
  folioId,
}: {
  pmsReference?: string | null;
  discoverId?: string;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  folioId: string | null;
}) =>
  useQuery(
    [QueryKeys.CHECKOUT_FOLIO_ID, pmsReference, folioId],
    () =>
      getReservationFolioById({
        pmsReference,
        discoverId,
        consumerData,
        consumerAccessToken,
        folioId,
      }),
    {
      enabled: !!pmsReference && !!discoverId && !!folioId,
      ...noPressure,
      staleTime: 0,
    }
  );

export const useGetOrderSubIdForLocation = ({
  locationId,
  apiKey,
}: {
  locationId: string | undefined;
  apiKey: string | null;
}) => {
  return useQuery(
    [QueryKeys.ORDER_SUBSCRIPTION_ID, locationId],
    () =>
      getOrderSubIdForLocation({
        locationId,
        apiKey,
      }),
    {
      enabled: !!locationId && !!apiKey,
      ...noPressure,
    }
  );
};

export const useGetActiveStayReservationsForConsumer = ({
  consumerAccessToken,
  consumerData,
  discoverId,
  isPMSconnected,
}: {
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  discoverId?: string;
  isPMSconnected: boolean;
}) => {
  return useQuery(
    [QueryKeys.STAY_RESERVATIONS, consumerAccessToken],
    () =>
      getActiveStayReservationsForConsumer({
        consumerAccessToken,
        consumerData,
        discoverId,
      }),
    { ...noPressure, enabled: isPMSconnected }
  );
};

export const useGetStayBooking = ({
  pmsReference,
  discoverId,
  consumerAccessToken,
  consumerData,
  isPMSconnected,
}: {
  pmsReference?: string | null;
  discoverId?: string;
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  isPMSconnected: boolean;
}) => {
  return useQuery(
    [QueryKeys.STAY_BOOKING, pmsReference],
    () =>
      getBooking({
        pmsReference,
        discoverId,
        consumerAccessToken,
        consumerData,
      }),
    {
      retry: false,
      enabled: !!pmsReference && !!discoverId && isPMSconnected,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetReservationOrdersForConsumer = ({
  pmsReference,
  consumerAccessToken,
  consumerData,
  isPMSconnected,
}: {
  pmsReference?: string | null;
  consumerAccessToken?: string | null;
  consumerData: ConsumerDataType | null;
  isPMSconnected: boolean;
}) => {
  return useQuery(
    [QueryKeys.STAY_RESERVATION_ORDERS, pmsReference],
    () =>
      getReservationOrdersForConsumer({
        consumerAccessToken,
        consumerData,
        pmsReference,
      }),
    { ...noPressure, enabled: isPMSconnected }
  );
};

export const useGetUploadedImageById = ({
  discoverId,
  pmsReference,
  consumerData,
  consumerAccessToken,
  uploadId,
}: {
  discoverId: string;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
  uploadId: string | null;
}) =>
  useQuery(
    [QueryKeys.UPLOADED_IMAGE],
    () =>
      getImageUrl({
        discoverId,
        pmsReference,
        consumerData,
        consumerAccessToken,
        uploadId,
      }),
    { enabled: !!uploadId }
  );

export const useGetGroupSettingsByDiscoverId = ({
  discoverId,
}: {
  discoverId: string | undefined;
}) =>
  useQuery(
    [QueryKeys.GROUP_SETTINGS, discoverId],
    () =>
      getGroupSettingsByDiscoverId({
        discoverId,
      }),
    { enabled: !!discoverId, ...noPressure }
  );

export const useGetOrdersForConsumer = ({
  consumerData,
  consumerAccessToken,
}: {
  consumerData: ConsumerDataType | null;
  consumerAccessToken?: string | null;
}) =>
  useQuery(
    [QueryKeys.CONSUMER_ORDERS, consumerData],
    () => getOrdersForConsumer(consumerData, consumerAccessToken),
    { enabled: !!consumerData || !!consumerAccessToken }
  );

export const useNearbyLocations = ({
  discoverId,
  type = LocationType.RESTAURANT_TYPE,
  limit = 10,
  disableStayConciergeLocationsNearBy,
}: {
  discoverId?: string;
  type?: LocationType;
  limit?: number;
  disableStayConciergeLocationsNearBy?: boolean;
}) => {
  return useQuery(
    [QueryKeys.NEARBY_LOCATIONS, discoverId, type, limit],
    () => fetchNearbyLocations({ discoverId, type, limit }),
    {
      enabled: !!discoverId && !disableStayConciergeLocationsNearBy,
      ...noPressure,
    }
  );
};

export const useGetConsumerHotelGuestWishOptions = (
  locationId: string,
  consumerData: ConsumerDataType | null
) => {
  return useQuery(
    [QueryKeys.CONSUMER_HOTEL_GUEST_WISH_OPTIONS, locationId],
    () => getConsumerHotelGuestWishOptions(locationId, consumerData),
    {
      enabled: !!locationId && !!consumerData,
    }
  );
};

export const useGetConsumerHotelGuestWishes = ({
  discoverId,
  pmsReference,
  consumerData,
}: {
  discoverId: string;
  pmsReference: string;
  consumerData: ConsumerDataType | null;
}) => {
  return useQuery(
    [QueryKeys.CONSUMER_HOTEL_GUEST_WISHES, discoverId, pmsReference],
    () =>
      getConsumerHotelGuestWishesForReservation({
        discoverId,
        pmsReference,
        consumerData,
      }),
    {
      enabled: !!discoverId && !!pmsReference && !!consumerData,
    }
  );
};

export const useGetReceiptPayment = ({
  shopperReceiptsAccessToken,
}: {
  shopperReceiptsAccessToken?: string | null;
}) =>
  useQuery(
    [QueryKeys.RECEIPT_PAYMENT, shopperReceiptsAccessToken],
    () => getReceiptPayment(shopperReceiptsAccessToken),
    { enabled: !!shopperReceiptsAccessToken, retry: false }
  );

export const useFeaturedLocations = ({
  discoverId,
  type = LocationType.RESTAURANT_TYPE,
  limit = 10,
  disableStayConciergeLocationsNearBy,
}: {
  discoverId: string;
  type?: LocationType;
  limit?: number;
  disableStayConciergeLocationsNearBy?: boolean;
}) => {
  return useQuery(
    [QueryKeys.FEATURED_LOCATIONS, discoverId, type, limit],
    () => fetchFeaturedLocations(discoverId, type, limit),
    {
      enabled: !!discoverId && !disableStayConciergeLocationsNearBy,
      ...noPressure,
    }
  );
};

export const useGetSpecialStayOffers = ({
  locationId,
  disableStaySpecialOffers,
}: {
  locationId?: string | null;
  disableStaySpecialOffers?: boolean;
}) =>
  useQuery(
    [QueryKeys.SPECIAL_STAY_OFFERS, locationId, disableStaySpecialOffers],
    () => getSpecialStayOffers(locationId),
    {
      enabled:
        !!locationId &&
        !disableStaySpecialOffers &&
        disableStaySpecialOffers !== undefined,
      ...noPressure,
      cacheTime: 30_000,
    }
  );

export const useGetMinibarOffers = ({
  locationId,
}: {
  locationId?: string | null;
}) =>
  useQuery(
    [QueryKeys.MINIBAR_OFFERS, locationId],
    () => getMinibarOffers(locationId),
    {
      enabled: !!locationId,
      ...noPressure,
      cacheTime: 30_000,
    }
  );

export const useGetPossibleRoomUpgrade = ({
  discoverId,
  pmsReference,
  consumerData,
  disableStayUpsells,
}: {
  discoverId?: string | null;
  pmsReference?: string | null;
  consumerData: ConsumerDataType | null;
  disableStayUpsells?: boolean;
}) =>
  useQuery(
    [
      QueryKeys.POSSIBLE_ROOM_UPGRADE,
      pmsReference,
      disableStayUpsells,
      discoverId,
    ],
    () => getPossibleRoomUpgrade(consumerData, discoverId, pmsReference),
    {
      enabled:
        !!discoverId &&
        !!pmsReference &&
        !disableStayUpsells &&
        disableStayUpsells !== undefined,
      ...noPressure,
    }
  );

export const useGetPublicOrderById = (orderId?: string) =>
  useQuery(
    [QueryKeys.PUBLIC_ORDER, orderId],
    () => getPublicOrderById(orderId),
    {
      enabled: !!orderId,
    }
  );
