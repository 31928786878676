import { API_PATH } from 'constants/routes';
import {
  ConsumerDataType,
  ConsumerReservationResponseType,
  GetReservationExperienceResponseType,
  LocationData,
  LocationTableResponseType,
  LocationType,
} from 'types';
import {
  convertToSearchParametersString,
  getAccessTokenForRequest,
} from 'utils';

import { ApiError, headers } from './constants';
import { validateAndParse } from './utils';

export const getProductMadeById = (productMadeId: string) =>
  fetch(`${API_PATH}/v4/redirects/pm/${productMadeId}`, {
    method: 'GET',
    headers,
  }).then(response => {
    if (response.status !== 200) {
      return { errorResponse: response.status };
    }
    return response.json();
  });

export const getScanner = (scannerId?: string) => {
  return fetch(`${API_PATH}/v3/scanners/${scannerId}`)
    .then(validateAndParse)
    .catch(error => {
      throw new Error(`Error requesting scanner with status - ${error.status}`);
    });
};

export const getLocation = (locationId: string): Promise<LocationData> => {
  return fetch(`${API_PATH}/v3/locations/${locationId}`)
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting location with status - ${error.status}`
      );
    });
};

export const getLocationGroup = (locationGroupId: string) => {
  return fetch(`${API_PATH}/v3/locationGroups/${locationGroupId}`)
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting location group with status - ${error.status}`
      );
    });
};

export const getDiscoverLocationGroup = (
  discoverId: string | null | undefined
) => {
  return fetch(`${API_PATH}/v4/locationGroups/discover/${discoverId}`)
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting discover location group with status - ${error.status}`
      );
    });
};

export const getLocationURLs = (locationId?: string) => {
  return fetch(`${API_PATH}/v3/locations/${locationId}/urls`)
    .then(validateAndParse)
    .catch(error => {
      throw new Error(
        `Error requesting location urls with status - ${error.status}`
      );
    });
};

export const getLocationTableById = (tableId?: string | null) =>
  fetch(`${API_PATH}/v4/locationTables/${tableId}`, {
    method: 'GET',
    headers,
  }).then(apiResponse => {
    if (apiResponse.status !== 200) {
      return { errorResponse: apiResponse.status };
    }
    return apiResponse.json();
  });

export const getConsumerReservation = async (
  consumerAccessToken: string
): Promise<ConsumerReservationResponseType> => {
  const response = await fetch(
    `${API_PATH}/v4/consumers/reservations?token=${consumerAccessToken}`,
    { headers }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const cancelReservation = async (consumerAccessToken: string) => {
  const response = await fetch(
    `${API_PATH}/v4/consumers/reservations/cancel?token=${consumerAccessToken}`,
    {
      method: 'POST',
      headers,
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }
};

export const getClientConfig = (): Promise<{
  [key: string]: boolean;
} | null> => {
  return fetch(`${API_PATH}/v4/clientConfigs/webapp`)
    .then(response => response.json())
    .catch(error => {
      throw new Error(
        `Error requesting clientConfig  with status - ${error.status}`
      );
    });
};

export const getReservationExperience = async ({
  consumerData,
  experienceId,
}: {
  consumerData?: ConsumerDataType | null;
  experienceId?: string;
}): Promise<GetReservationExperienceResponseType | null> => {
  let authToken = null;

  if (consumerData) {
    authToken = await getAccessTokenForRequest(consumerData);
  }

  const headersForRequest = authToken
    ? { ...headers, 'X-Auth': authToken }
    : { ...headers };

  const response = await fetch(
    `${API_PATH}/v5/consumers/reservations/experiences/${experienceId}`,
    {
      method: 'GET',
      headers: headersForRequest,
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create bundle checkout, status code ${response.status}`
    );
  }
  return (await response.json()) as GetReservationExperienceResponseType;
};

export const getCorrespondingTableByName = async ({
  tableNameOrNumber,
  locationId,
}: {
  tableNameOrNumber?: string;
  locationId: string;
}): Promise<LocationTableResponseType[]> => {
  const searchParameters = tableNameOrNumber
    ? `?${convertToSearchParametersString({
        tableNameOrNumber,
      })}`
    : '';
  const response = await fetch(
    `${API_PATH}/v5/public/locations/${locationId}/locationTables/search${searchParameters}`,
    { headers }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  return response.json();
};

export const getJwtForLinkDevice = async (apiKey: string | null) => {
  const response = await fetch(
    `${API_PATH}/v4/auth/operatorDevice/login/apiKey`,
    {
      method: 'POST',
      headers: { ...headers, 'X-API-KEY': apiKey || '' },
    }
  );

  if (!response.ok) {
    throw new ApiError(response);
  }

  const jwtResponse = await fetch(`${API_PATH}/v4/auth/jwt`, {
    method: 'GET',
    headers,
  });

  if (!jwtResponse.ok) {
    throw new ApiError(response);
  }

  return jwtResponse.text();
};

export const fetchNearbyLocations = async ({
  discoverId,
  type = LocationType.RESTAURANT_TYPE,
  limit = 10,
}: {
  discoverId?: string;
  type: LocationType;
  limit: number;
}) => {
  const response = await fetch(
    `${API_PATH}/v5/public/discover/${discoverId}/locations/nearby?type=${type}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Error fetching nearby locations: ${response.statusText}`);
  }

  return response.json();
};

export const fetchFeaturedLocations = async (
  discoverId: string,
  type: LocationType = LocationType.RESTAURANT_TYPE,
  limit: number = 10
) => {
  const response = await fetch(
    `${API_PATH}/v5/public/discover/${discoverId}/featuredLocations/nearby?type=${type}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Error fetching nearby locations: ${response.statusText}`);
  }

  return response.json();
};
