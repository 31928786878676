import { createContainer } from 'unstated-next';
import { HotelCheckinContainerTypes } from 'types';
import {
  useGetLocation,
  useGetLocationGroup,
  useGetLocationGroupPaymentSettingsV3,
  useGetLocationTableById,
  useGetLocationURLs,
} from 'hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { extractTableFromUrl, isValidUUID } from 'utils';

const useHotelCheckinContainer = (): HotelCheckinContainerTypes => {
  const { locationId } = useParams();
  const tableInfo = extractTableFromUrl();
  const isValidUuid = isValidUUID(locationId);
  const [searchParameters] = useSearchParams();
  const backFromMenu = searchParameters.get('backFromMenu');

  if (!isValidUuid) {
    throw new Error('Called page without valid locationId');
  }

  const {
    data: location,
    error: locationError,
    isLoading: isLocationLoading,
  } = useGetLocation(locationId);

  const {
    data: table,
    error: tableError,
    isLoading: isTableLoading,
  } = useGetLocationTableById(tableInfo);

  const {
    data: group,
    error: groupError,
    isLoading: groupLoading,
  } = useGetLocationGroup(location?.groupId);

  const {
    data: groupSettings,
    error: groupSettingsError,
    isLoading: isGroupSettingsLoading,
  } = useGetLocationGroupPaymentSettingsV3(location?.groupId);

  const { data: links } = useGetLocationURLs(locationId);

  return {
    location,
    locationError,
    isLocationLoading,
    table,
    tableInfo,
    tableError,
    isTableLoading,
    group,
    groupError,
    groupLoading,
    backFromMenu: !!backFromMenu,
    groupSettings,
    groupSettingsError,
    isGroupSettingsLoading,
    links,
  };
};

export const HotelCheckinContainer = createContainer(useHotelCheckinContainer);
