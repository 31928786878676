import { useIntl } from 'react-intl';
import { useMountEffect } from '@react-hookz/web';
import { AnalyticsViewWrapper } from 'components/general';

type PropertiesType = { children: JSX.Element; title: string } & (
  | { analyticsIdentifier: string }
  | { legacyLocaleId: string }
);
export const Page = ({
  children,
  title,
  ...restProperties
}: PropertiesType): JSX.Element => {
  const intl = useIntl();

  useMountEffect(() => {
    const appTitle = intl.formatMessage({ id: 'webapp.site.title.prefix' });
    const pageTitle = intl.formatMessage({ id: title });

    document.title = `${appTitle} - ${pageTitle}`;
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AnalyticsViewWrapper {...restProperties}>{children}</AnalyticsViewWrapper>
  );
};
