import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { message } from 'antd';
import { createContainer } from 'unstated-next';

import {
  useGetBundle,
  useGetLocationGroupSettingsByLocationId,
  useGetPreorderLocations,
} from 'hooks';
import { useConsumerContext } from 'context';
import { ThemeType } from 'types';

const useThemeContainer = (initialTheme?: ThemeType) => {
  const [theme] = useState(initialTheme);

  const intl = useIntl();
  const { discoverId, locationId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [searchParameters] = useSearchParams();
  const { consumerData } = useConsumerContext();

  const consumerAccessToken = useMemo(
    () => searchParameters.get('accessToken'),
    [searchParameters]
  );
  const fallbackDiscoverId = useMemo(
    () => searchParameters.get('discoverId'),
    [searchParameters]
  );

  const { data: bundle, error: bundleError } = useGetBundle({
    consumerAccessToken,
    consumerData,
    enabled: !theme,
  });

  const { bundleDiscoverId } = state || {};

  if (
    // @ts-ignore cant infer custom error
    bundleError?.error === 'NO_CONSUMER_ACCESS_TOKEN' &&
    !bundleDiscoverId &&
    !theme
  ) {
    message.error({
      content: intl.formatMessage({
        id: 'preorder.error.missingAccessToken',
      }),
      key: 'unique_error_message',
    });
    navigate(`/preorder/${fallbackDiscoverId}`);
  }

  const routeDiscoverId =
    discoverId || bundleDiscoverId || bundle?.locationDiscoverId;
  const { data: preorderLocations } = useGetPreorderLocations(routeDiscoverId);
  const { data: groupSettings, isLoading: isGroupSettingsLoading } =
    useGetLocationGroupSettingsByLocationId(
      (locationId || (preorderLocations && preorderLocations[0]?.locationId)) ??
        undefined
    );

  return {
    ...(groupSettings?.theme || theme),
    isGroupSettingsLoading,
  };
};

export const ThemeContainer = createContainer(useThemeContainer);
