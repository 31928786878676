import { createContainer } from 'unstated-next';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckoutContainerTypes } from 'types';
import {
  useGetCheckout,
  useGetLocation,
  useGetLocationGroupConfig,
  useGetLocationGroupSettingsByLocationId,
} from 'hooks';
import { isAndroidReferer } from 'utils';
import { useConsumerContext } from 'context';

const useCheckoutContainer = (): CheckoutContainerTypes => {
  const { checkoutId } = useParams();
  const [searchParameters] = useSearchParams();
  const checkoutIdFromSearchParameters = searchParameters.get('checkoutId');

  const { consumerData } = useConsumerContext();

  const consumerAccessToken = searchParameters.get('showBundleAccessToken');
  const isLightTheme = searchParameters.get('isLightTheme');

  const checkoutIdToBeUsed = checkoutId || checkoutIdFromSearchParameters;

  if (!checkoutIdToBeUsed) {
    console.error('Called page without valid checkoutId');
  }

  const {
    error: checkoutError,
    data: checkout,
    isLoading: isCheckoutLoading,
    refetch: refetchCheckout,
  } = useGetCheckout(checkoutIdToBeUsed);

  const { data: location, isLoading: isLocationLoading } = useGetLocation(
    checkout?.locationId
  );

  const { data: config, isLoading: isConfigLoading } =
    useGetLocationGroupConfig(consumerData, location?.discoverId);

  const { data: groupSettings, isLoading: isGroupSettingsLoading } =
    useGetLocationGroupSettingsByLocationId(checkout?.locationId ?? undefined);

  if (isAndroidReferer(searchParameters)) {
    // Do never show success screen for android native payments
    window.close();
  }

  return {
    checkoutId: checkoutIdToBeUsed,
    isLightTheme: !!isLightTheme,
    consumerAccessToken,
    checkout,
    groupSettings,
    checkoutError,
    config,
    isConfigLoading,
    isCheckoutLoading,
    isGroupSettingsLoading,
    refetchCheckout,
    isLocationLoading,
    isHotelLocation: location?.locationType === 'hotel' || false,
  };
};

export const CheckoutContainer = createContainer(useCheckoutContainer);
